import React, { createRef, forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import { createRoot } from 'react-dom/client'
import { Dialog, Transition } from '@headlessui/react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'app'
import { BrowserRouter } from 'react-router-dom'

type DrawerProps = {
  id?: string;
  component?: any;
  header?: {
    title?: string|JSX.Element;
    subtitle?: string;
    bg?: string;
    hide?: boolean;
  };
  footer?: {
    reverse?: boolean;
    hide?: boolean;
  };
  primaryButton?: {
    text?: string;
    onClick?: () => void;
  };
  secondaryButton?: {
    text?: string;
    onClick?: () => void;
  };
  children?: any;
  maxWidth?: string;
  fromRight?: boolean;

  onClose?: () => void;
}

const drawers = document.getElementById('drawers')
const root = drawers && createRoot(drawers) || null

export const showDrawer = (props: DrawerProps) => {
  if (!drawers || !root) return null

  const ref = createRef<any>()

  root.render(
    <Drawer
      ref={ref}
      key={props.id || String(new Date())}
      defaultShow={true}
      {...props}
    />
  )

  return ref
}

const Drawer = forwardRef((props: DrawerProps & {defaultShow: boolean}, ref) => {
  const [show, setShow] = useState(props.defaultShow)
  const [tShow, setTshow] = useState(props.defaultShow)

  useEffect(() => {
    document.body.classList.add('modal-open')
  }, [])

  useImperativeHandle(ref, () => ({
    close,
  }))

  const close = () => {
    setShow(false)
    document.body.classList.remove('modal-open')
    setTimeout(() => {
      setTshow(false)
      props.onClose && props.onClose()
    }, 300)
  }

  if (!tShow) return null

  const mainDivCn = classNames({
    'left-0 pr-10': !props.fromRight,
    'right-0 pl-10': props.fromRight,
    'fixed inset-y-0 max-w-full flex': true,
  })

  const enterFromLeaveTo = props.fromRight ? 'translate-x-full' : '-translate-x-full'
  const enterToLeaveFrom = props.fromRight ? '-translate-x-0' : 'translate-x-0'

  return (
    <ApolloProvider client={client}>
      <Transition.Root show={show} appear={true} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden" style={{ zIndex: 1060 }} open={true} onClose={close}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className={mainDivCn}>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-500 sm:duration-700"
                enterFrom={enterFromLeaveTo}
                enterTo={enterToLeaveFrom}
                leave="transition ease-in-out duration-500 sm:duration-700"
                leaveFrom={enterToLeaveFrom}
                leaveTo={enterFromLeaveTo}>
                <div className={`w-screen ${props.maxWidth || 'max-w-md'} h-full`}>
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    {props.header && (
                      <DrawerHeader
                        title={props.header?.title || 'Title'}
                        subtitle={props.header?.subtitle}
                        bg={props.header?.bg}
                        close={close}
                      />
                    )}

                    <section className="flex-1 h-0 overflow-y-auto">
                      {props.component}
                    </section>

                    {!props.footer?.hide && (
                      <DrawerFooter
                        reverse={props.footer?.reverse || false}
                        primaryText={props.primaryButton?.text || intl.get('done_text')}
                        primaryAction={() => props.primaryButton?.onClick && props.primaryButton.onClick() || null}
                        secondaryText={props.secondaryButton?.text || intl.get('global_cancel')}
                        secondaryAction={() => props.secondaryButton?.onClick && props.secondaryButton.onClick() || null}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ApolloProvider>
  )
})

export default Drawer

type DrawerHeaderProps = {
  title: string|JSX.Element;
  subtitle: string|undefined;
  bg?: string;
  close: () => void;
}

export const DrawerHeader = ({ title, subtitle, bg, close }: DrawerHeaderProps) => {
  return (
    <header className={`space-y-1 py-6 px-4 ${bg || 'bg-lake'} sm:px-6`}>
      <div className="flex items-start justify-between space-x-3">
        <div className="text-lg leading-7 font-medium text-deepgray">
          {title}
        </div>

        <div className="h-7">
          <button aria-label="Close panel" className="text-medgray hover:text-coral transition ease-in-out duration-150" onClick={close}>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

      {subtitle &&
        <p className="text-sm leading-5 text-medgray pb-0 mb-0">
          {subtitle}
        </p>
      }
    </header>
  )
}

type DrawerFooterProps = {
  reverse: boolean;
  primaryText?: string;
  primaryDisabled?: boolean;
  secondaryText?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
}

export const DrawerFooter = ({ reverse, primaryText, primaryDisabled, secondaryText, primaryAction, secondaryAction }: DrawerFooterProps) => {
  return (
    <footer className={`shrink-0 px-4 py-4 flex space-x-2 bg-white border-t border-gray-200 ${reverse ? 'justify-end flex-row-reverse space-x-reverse' : 'justify-start'}`}>
      {primaryAction && (
        <span className="inline-flex rounded-md shadow-sm">
          <button disabled={!!primaryDisabled} onClick={primaryAction} className="btn-v2 team">
            {primaryText}
          </button>
        </span>
      )}

      {secondaryAction && (
        <span className="inline-flex rounded-md shadow-sm">
          <button onClick={secondaryAction} className="btn-v2 default">
            {secondaryText}
          </button>
        </span>
      )}
    </footer>
  )
}
