import { gql } from '@apollo/client'
import { billingServiceFragment, Organization, OrganizationProduct } from 'graphql/schemas/organization/Organization'


export type GetOrganizationsVars = {
  searchText: string;
  skip: number;
  limit: number;
  limitToProducts?: OrganizationProduct[];
  isPilot?: boolean;
  limitToPartnerOrganizations?: string;
  limitToManagerOrganizations?: string;
  limitToUserOrganizations?: string;
  disabled?: boolean;
}

export type GetOrganizationsRes = {
  res: {
    organizations: Pick<Organization, 'id'|'name'|'billingService'|'usedLicenses'|'featureFlags'|'product'|'logoUrl'|'disabled'|'customPalette'>[];
    organizationsCount: number;
  };
}

export const getOrganizationsQuery = gql`
  ${billingServiceFragment}

  query GetOrganizations ($searchText: String!, $skip: Int!, $limit: Int!,
    $limitToPartnerOrganizations: String, $limitToManagerOrganizations: String,
    $limitToUserOrganizations: String,
    $limitToProducts: [OrganizationProduct!],
    $isPilot: Boolean,
    $disabled: Boolean
  ) {
    res: getOrganizations (searchText: $searchText, skip: $skip, limit: $limit,
      limitToPartnerOrganizations: $limitToPartnerOrganizations,
      limitToManagerOrganizations: $limitToManagerOrganizations,
      limitToUserOrganizations: $limitToUserOrganizations,
      limitToProducts: $limitToProducts,
      isPilot: $isPilot,
      disabled: $disabled
    ) {
      organizations {
        id
        name
        logoUrl
        usedLicenses
        featureFlags
        product
        usedLicenses
        disabled
        customPalette {
          dashboardLogo
        }
        billingService {
          ...BillingServiceFragment
        }
      }
      organizationsCount
    }
  }
`

type OrgBillingSubscription = {
  billingPeriod: 'monthly'|'quarterly'|'yearly';
  expiresAt?: number;
  nextPaymentAt?: number;
  organizationId: string;
  // plan: { name: string; invoiceName: string; };
  planId: string;
  price: number;
  priceCurrency: string;
  purchasedLicenses: number;
  service: 'chargebee'|'freetier';
  startedAt: number;
  subscriptionId: string;
  trialExpiresAt?: number;
  // paymentSourceCard: OrgBillingPaymentSourceCard
  // renewalChanges: OrgBillingSubscriptionChanges
  status: 'pending'|'active'|'cancelled';
};

export type GetOrgBillingSubscriptionRes = {
  sub: OrgBillingSubscription | null | undefined;
}

export const getOrgBillingSubscriptionQuery = gql`
query GetOrgBillingSubscription ($organizationId: String!) {
  sub: getOrgBillingSubscription (organizationId: $organizationId) {
    billingPeriod
    expiresAt
    nextPaymentAt
    organizationId
    # plan {
    #  name
    #  invoiceName
    # }
    planId
    price
    priceCurrency
    purchasedLicenses
    # renewalChanges
    service
    startedAt
    status
    subscriptionId
    trialExpiresAt
  }
}
`

export const isSubscriptionCancelled = (subscription: OrgBillingSubscription | null | undefined) => {
  if (!subscription) {
    return false
  }
  if (subscription.status === 'cancelled' || (subscription.status === 'active' && !subscription.nextPaymentAt)) {
    return true
  }
  return false
}

export const getManagerAgentOrganizationsQuery = gql`
  query GetManagerAgentOrganizations($userId: String!) {
    organizations: getManagerAgentOrganizations(userId: $userId) {
      name
      id
      product
    }
  }
`
