import { checkHttpStatus } from 'actions/utils'
import { client } from 'app'
import { BASE_API_URL } from 'constants/General'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'
import { userRoles } from 'graphql/schemas/user/User'
import { GetUserSubscriptions2Res, GetUserSubscriptionsVars, getUserSubscriptions2Query } from 'graphql/queries/user/getUserSubscriptions'
import * as logger from 'logger'
import { getMeta, refetch } from 'utils/functions'
import { GetOrganizationsRes, GetOrganizationsVars, getOrganizationsQuery } from 'graphql/queries/organization/getOrganziations'
import { organizationProductName } from 'graphql/schemas/organization/Organization'
import { useLocation, Location } from 'react-router-dom'
import React from 'react'
import { history } from './history'
declare let analytics: any

const NO_IP = '0.0.0.0'

let _fetchedIp: undefined|Promise<string> = undefined

const getIp = async () => {
  if (/localhost/.test(document.location.host)) {
    return NO_IP
  }

  if (_fetchedIp) {
    return _fetchedIp
  }

  _fetchedIp = new Promise(async (fRes) => {
    try {
      const res = await refetch(`${BASE_API_URL}/api/v1/admin/my_ip`)
      const response = await checkHttpStatus(res)
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error.message)
      }
      if (!json.data || !json.data.ipMasked) {
        throw new Error('No data returned')
      }

      fRes(json.data.ipMasked)
    }
    catch (err) {
      logger.error('Error while fetching my_ip in track.tsx', { error: err })
      _fetchedIp = undefined
      fRes(NO_IP)
    }
  })
  return _fetchedIp
}

export const initSegment = (environment: string) => {
  try { // Segment
    const writeKey = environment === 'production' || environment === 'preproduction'
      ? 'ILruCwVPwVtdLckVb276sMv5are6VLvW'
      : environment === 'staging'
        ? 'ZyFQYxLCmvvVX3h3Vt63suk81lZzCuxm'
        : undefined
    const sourceId = environment === 'production' || environment === 'preproduction'
      ? 'QuL0bLyKYI'
      : environment === 'staging'
        ? 'bCS3WsAtBM1rpu732qZFrj'
        : ''

    const url = window.location.href
    const params = new URLSearchParams(new URL(url).search)
    const anonymousId = params.get("anonymousId")

    // @ts-ignore
    !function segmentLoad(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src=`https://evs.ping.gnowbe.com/38HuB52nfJ/${sourceId}.min.js`;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=writeKey;analytics._cdn="https://evs.ping.gnowbe.com";analytics.SNIPPET_VERSION="4.15.3";
      if (key) {
        analytics.load(key);
        page()
        if (anonymousId !== 'undefined' && anonymousId !== 'null' && !!anonymousId) {
          analytics.setAnonymousId(anonymousId)
        }
      }
    }}();
  } catch (error) {
    logger.error('Segment initialization error', error)
  }
}

export const identifyOnSegment = (userId: string, email: string) => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip =>
    analytics.identify(userId,
      {email},
      {context: {ip}},
    ),
  ).catch(err => {}) // Error should not happen
}

export const resetSegment = () => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }
  analytics.reset()
}

export const track = ({ event, variables }: { event: string, variables?: any }) => {
  const appData = client.readQuery<AppState>({ query: getAppStateQuery })

  const subData = client.readQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>({
    query: getCurrentSubscriptionQuery,
    variables: { userId: appData?.appState?.loggedInAs?.uid || '' },
  })

  const moreData = client.readQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>({
    query: getUserSubscriptions2Query,
    variables: { userId: appData?.appState?.loggedInAs?.uid || '', archived: false },
  })

  const orgData = client.readQuery<GetOrganizationsRes, GetOrganizationsVars>({
    query: getOrganizationsQuery,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    }
  })

  const workspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')
  const roles = userRoles(subData?.user, workspaceId)
  const groupIds = moreData?.response.companies.filter(c => c.organization?.id === workspaceId).map(company => company.id)
  const groupId = variables?.company_id || ''
  const workspaceProductId = orgData?.res?.organizations?.find(o => o.id === workspaceId)?.product || ''
  const workspaceProductName = organizationProductName(workspaceProductId)
  const workspacePlanId = orgData?.res?.organizations?.find(o => o.id === workspaceId)?.billingService?.planId || ''
  const workspacePlanName = orgData?.res?.organizations?.find(o => o.id === workspaceId)?.billingService?.planName || ''
  const platform = 'web'
  const workspaceName = orgData?.res?.organizations?.find(o => o.id === workspaceId)?.name || ''
  const groupName = moreData?.response.companies.find(c => c.id === groupId)?.name || ''

  const allData = { ...variables, workspaceId, roles, groupId, groupIds, workspaceProductId, workspaceProductName, workspacePlanId, workspacePlanName, platform, workspaceName, groupName }

  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip => {
    if (typeof Cypress === 'undefined') {
      analytics.track(
        event,
        allData || {},
        {context: {ip}},
      )
    }
  }).catch(err => {})
}

export const trackButton = ({ button, on_screen, company_id, course_id, user_id }: { button: string, on_screen: string, company_id?: string, course_id?: string, user_id?: string }) => {
  track({
    event: 'Button Clicked',
    variables: {
      button,
      on_screen,
      company_id,
      course_id,
      user_id,
    },
  })
}
export const trackButtonEnhanced = ({ button, onScreen }: { button: string, onScreen: string }) => {
  track({
    event: `Button ${button} Clicked`,
    variables: {
      onScreen,
    },
  })
}



export const trackUpsell = ({ button, on_screen, company_id, course_id, user_id, type }: { button: string, on_screen: string, company_id?: string, course_id?: string, user_id?: string, type?: 'shown'|'clicked' }) => {
  track({
    event: type === 'shown' ? 'Upsell Shown' : 'Upsell Clicked',
    variables: {
      button,
      on_screen,
      company_id,
      course_id,
      user_id,
    },
  })
}
export const trackUpsellEnhanced = ({ button, onScreen, programId, type }: { button: string, onScreen: string, programId?: string, type?: 'shown'|'clicked' }) => {
  track({
    event: type === 'shown' ? 'Upsell Modal Shown' : 'Upsell Navigation Clicked',
    variables: {
      button,
      onScreen,
      programId,
    },
  })
}

export const page = () => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip =>
    analytics.page({}, {context: {ip}}),
  ).catch(err => {}) // Error should not happen
}

// history.listen((loc, act) => {
//   page()
// })