import React from 'react'
import classNames from 'classnames'

export const CategoryHeader = ({ title, order, showBorder, onClick }: { title: string, order?: 'asc'|'desc', showBorder?: boolean, onClick?: () => void }) => {
  const classname = classNames({
    'uppercase font-bold text-actions-multiple_choice md:text-lg tracking-wider flex justify-between items-center': true,
    'border-b border-gray-200 pb-4': showBorder,
    'cursor-pointer': onClick && order,
  })

  return (
    <div className={classname} onClick={onClick}>
      {title}

      {!!order && (
        <span className="inline-block text-lg font-bold">
          {order === 'asc' && (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-180" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          )}
          {order === 'desc' && (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          )}
        </span>
      )}
    </div>
  )
}
