import React from 'react'
import intl from 'react-intl-universal'

export const Help = React.memo(({ icon, title, path, variables, classNames, hide }: { icon?: string, title?: string, path: string, variables?: Object, classNames?: string, hide?: boolean }) => {
  let additionalStyle = {}

  if (path === 'firehelp_chapter_title') {
    additionalStyle = { textAlign: 'left', fontFamily: '"NotoSans", "NeueHaasUnicaPro", "Helvetica Neue", Helvetica, Arial, sans-serif' }
  }

  let txt
  if (variables) {
    txt = intl.getHTML(path, variables)
  }
  else {
    txt = intl.getHTML(path)
  }

  return (
    <div className={`fire-container ${hide ? 'hidden' : ''} ${classNames || ''}`} style={additionalStyle}>
      <div className="fire-content p-4 leading-relaxed text-sm overflow-auto">
        <div className="mb-2">
          <i className={`${icon ? icon : 'icon-question-circle-light'} font-bold mr-2 mt-1 float-left`} />
          <strong>{title || intl.get('global_hint')}</strong><br/>
        </div>

        <div className="text">{txt}</div>
      </div>
    </div>
  )
})
