import React from 'react'
import { MessagesIcon, ActivityIcon } from 'components/navbar/buttons/ActivityIcon'
import { UserMenu } from 'components/navbar/RegularMenu'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import HelpAndUpdateCenter from './HelpAndUpdateCenter'
import { useNavigate } from 'react-router-dom'
import FeatureUpdatesIcon from 'components/FeatureUpdatesIcon'

export const HorizontalMenu = ({ companyId, courseId, mobile, showTour, showPlanButtons, openTour, workspacesTour }: { companyId: string, courseId: string, mobile?: boolean, showTour: boolean, showPlanButtons: boolean, openTour: () => void, workspacesTour: () => void }) => {
  const navigate = useNavigate()
  const click = (id: string, path: string) => {
    trackButton({
      button: id,
      on_screen: 'home',
    })
    navigate(path)
  }

  return (
    <div className="flex items-center space-x-5 ml-8">
      {/* {showTour && (
        <div className="flex items-center" id="tour-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 inline-block mr-2"><path d="M64 496C64 504.8 56.75 512 48 512h-32C7.25 512 0 504.8 0 496V32c0-17.75 14.25-32 32-32s32 14.25 32 32V496zM476.3 0c-6.365 0-13.01 1.35-19.34 4.233c-45.69 20.86-79.56 27.94-107.8 27.94c-59.96 0-94.81-31.86-163.9-31.87C160.9 .3055 131.6 4.867 96 15.75v350.5c32-9.984 59.87-14.1 84.85-14.1c73.63 0 124.9 31.78 198.6 31.78c31.91 0 68.02-5.971 111.1-23.09C504.1 355.9 512 344.4 512 332.1V30.73C512 11.1 495.3 0 476.3 0z" fill="#1B585E" /></svg>
          <button className="text-actions-multiple_choice font-medium underline" onClick={openTour}>
            Take the tour!
          </button>
        </div>
      )} */}
      <FeatureUpdatesIcon onlyIcon={true} />

      <HelpAndUpdateCenter mobile={mobile} showTour={showTour} openTour={openTour} workspacesTour={workspacesTour}></HelpAndUpdateCenter>

      <MessagesIcon
        home={true}
        active={false}
        onClick={() => {
          click('messages', '/chat')
          trackButtonEnhanced({
            button: 'Messages',
            onScreen: 'Home (Horizontal Menu)',
          })
        }}
      />

      <ActivityIcon
        mini={true}
        home={true}
        mobile={mobile}
        active={false}
        companyId={companyId}
        courseId={courseId}
        onClick={() => click('notifications_bell', '/activity')}
      />

      {/* <button className="icon icon-search-light text-xl font-medium cursor-pointer text-deepgray hover:text-coral transition-none" onClick={() => click('search', '/search')} /> */}

      {!mobile && (
        <div className="relative z-20">
          <UserMenu
            home={true}
            isActive={false}
            showPlanButtons={showPlanButtons}
          />
        </div>
      )}
    </div>
  )
}

export const MobileHorizontalMenu = ({ openChapterList }) => {
  const navigate = useNavigate()
  return (
    <>
      <button className="h-12 w-12 icon icon-search-light text-xl font-medium cursor-pointer text-deepgray hover:text-coral transition-none" onClick={() => navigate('/search')} />
      <button className="h-12 w-12 icon icon-session-list text-xl font-medium cursor-pointer text-deepgray hover:text-coral transition-none" onClick={openChapterList} />
    </>
  )
}
