import intl from 'react-intl-universal'
import { client } from 'app'
import { showModal } from 'components/utils/CustomModal'
import { getStudyForSubscriptionQuery } from 'graphql/queries/user/getStudyForSubscription'
import { Reward } from './Reward'
import { resetUserAssessments } from 'actions/courses_actions'
import { UserStudy } from '../user/UserStudy'
import { isActionCompleted } from 'models/action_models'
import { useNavigate } from 'react-router-dom'

export type ContentType = 'multiple_choice'|'qa'|'photo'|'image'|'link'|'text'|'audio'|'external'|'quote'|'video'|'certificate'|'auth_web_link'|'assessment'|'upload'|'rating'|'word_puzzle'

export const actionFactory = (data?: Partial<Action>): Action => {
  return {
    id: data?.id || 'random',
    chapterId: data?.chapterId || 'random',
    courseId: data?.courseId || 'random',
    order: data?.order || 1,
    contentType: data?.contentType || 'text',
    userAction: data?.userAction || 'read',
    title: data?.title || 'some title',
    rewards: data?.rewards || [],
    timestamps: data?.timestamps || {
      createdAt: 0,
      createdAtTree: 0,
      updatedAt: 0,
      updatedAtTree: 0,
    },
    invisible: data?.invisible || false,
    hints: data?.hints || [],

    lives: data?.lives || 0,
    description: data?.description || null,
    descriptionShort: data?.descriptionShort || null,
    placeholderAnswer: data?.placeholderAnswer || null,
    imageUrl: data?.imageUrl || null,
    contentUrl: data?.contentUrl || null,
    contentUrlOrg: data?.contentUrlOrg || null,
    contentUrlOrgEnabled: data?.contentUrlOrgEnabled || null,
    contentUrl_720p: data?.contentUrl_720p || null,
    contentUrlThumbnail: data?.contentUrlThumbnail || null,
    contentUrlAlt: data?.contentUrlAlt || null,
    audioUrl: data?.audioUrl || null,
    singleChoice: data?.singleChoice || null,
    interactionTime: data?.interactionTime || null,
    mcPoolingEnabled: data?.mcPoolingEnabled || null,
    linkMIMEType: data?.linkMIMEType || null,
    manualReviewMode: data?.manualReviewMode || 'hide',
  }
}

export type Action = {
  id: string;
  chapterId: string;
  courseId: string;
  order: number;
  contentType: ContentType;
  userAction: string;
  title: string;
  rewards: Reward[];
  timestamps:  {
    createdAt: number;
    createdAtTree: number;
    updatedAt: number;
    updatedAtTree: number;
  };
  invisible: boolean;
  hints: string[];

  lives: number|null;
  description: string|null;
  descriptionShort: string|null;
  placeholderAnswer: string|null;
  imageUrl: string|null;
  contentUrl: string|null;
  contentUrlOrg: string|null;
  contentUrlOrgEnabled: boolean|null;
  contentUrl_720p: string|null;
  contentUrlThumbnail: string|null;
  contentUrlAlt: string|null;
  audioUrl: string|null;
  singleChoice: boolean|null;
  interactionTime: number|null;
  mcPoolingEnabled: boolean|null;
  linkMIMEType: string|null;
  manualReviewMode: 'rating'|'norating'|'hide'|null;
}

export type ActionBasic = {
  id: string;
  chapterId: string;
  courseId: string;
  order: number;
  contentType: string;
  userAction: string;
  title: string;
  description?: string;
  descriptionShort?: string;
}

export const anyActionCompleted = (actions: Action[], studies: UserStudy[]) => {
  return actions.some(action => {
    const study = studies.find(s => s.actionId === action.id) || null
    return isActionCompleted(action.contentType, action.placeholderAnswer || '', study)
  })
}

export const allAssessmentsCompleted = (chapters, companyId, courseId, userId) => {
  const allAssessments: Action[] = chapters.reduce((a: Action[], c) => {
    return a.concat(c.actions.filter(a => a.contentType === 'assessment' && !a.invisible))
  }, [])

  if (!allAssessments.length) return true

  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      courseId,
      userId,
    },
  })

  if (!data) return false

  const allStudiesForAssessments = data.study.filter(s => allAssessments.some(a => a.id === s.actionId) && s.answer)
  return allAssessments.length <= allStudiesForAssessments.length
}

export const checkCertificate = ({ subscription, companyId, course, chapterId, assessmentsCompleted, assessmentsNum, courseEngagement, navigate }) => {
  if (assessmentsNum > 0 && (!subscription.progress || !subscription.progress.assessmentsCompleted)) {
    showModal({
      title: intl.get('session_certificate_assessments_not_completed_title'),
      content: intl.get('session_certificate_assessments_not_completed'),
      primaryText: intl.get('deeplink_message_open_session'),
      primaryAction: () => navigate(`/learn/${chapterId}`),
    })

    return false
  }

  // if all assessments answered but score too low
  if (assessmentsNum > 0 && subscription.progress && assessmentsCompleted && (subscription.progress.assessmentsGrade || 0) < course.passingGrade) {
    showModal({
      title: intl.get('session_fragment_grade_too_low'),
      content: intl.get('session_certificate_assesments_percentage_low', { 0: subscription.progress.assessmentsGrade, 1: course.passingGrade }),
      primaryText: intl.get('session_fragment_go_learn'),
      secondaryText: intl.get('session_fragment_reset_score'),
      secondaryButton: !!(assessmentsCompleted && course.canRetakeActionAssessments && (subscription.progress.assessmentsRetakeNum || 0) < course.maxActionAssessmentsRetakes),
      secondaryAction: () => {
        if (subscription.company) {
          resetUserAssessments({
            companyId,
            courseId: course.id,
          })
        }
        navigate(`/learn/${chapterId}`)
      },
    })

    return false
  }

  // if program engagement is too low
  if (courseEngagement < course.graduationGrade) {
    showModal({
      title: intl.get('error_title_oops'),
      content: intl.get('session_certificate_engagement', { 0: courseEngagement, 1: course.graduationGrade }),
      primaryText: intl.get('session_fragment_find_incomplete'),
      primaryAction: () => navigate('/workbook/progress'),
      secondaryText: intl.get('global_back'),
      secondaryAction: () => navigate(`/learn/${chapterId}`),
    })

    return false
  }

  return true
}

export const upsellAction = (contentType, features) => {
  if (!features) return true

  if (contentType === 'assessment' && !features?.includes('action-assessment')) return true
  if (contentType === 'certificate' && !features?.includes('action-certificate')) return true

  return false
}

export const showRetakeAssessmentsTag = ({ assessmentsCompleted, assessmentsGrade, passingGrade, canRetakeActionAssessments, assessmentsRetakeNum, maxActionAssessmentsRetakes }) => {
  if (assessmentsCompleted && assessmentsGrade < passingGrade && (!canRetakeActionAssessments || assessmentsRetakeNum >= maxActionAssessmentsRetakes)) return 'failed'
  if (assessmentsCompleted && assessmentsGrade < passingGrade && canRetakeActionAssessments && assessmentsRetakeNum < maxActionAssessmentsRetakes) return 'can-retake'
  return false
}
