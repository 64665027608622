import React, { Fragment, useState } from 'react'
import { produce } from 'immer'
import classNames from 'classnames'
import intl from 'react-intl-universal'
import { useMutation } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { updateAppCache } from 'app'
import { showModal } from 'components/utils/CustomModal'
import { DeleteChapterRes, DeleteChapterVars, deleteChapterMutation } from 'graphql/mutations/chapter/deleteChapter'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { showAlert } from 'components/utils/Alert'
import toast from 'react-hot-toast'
import Tippy from '@tippyjs/react'
import { Popover } from 'react-tiny-popover'
import { track } from 'utils/track'
import { useNavigate } from 'react-router-dom'

export const ChapterItem = ({ id, courseId, active, order, completion, title, locked, isEditing, streak, isLast }) => {
  const navigate = useNavigate()
  const ci = classNames({
    'new-chapter-item flex cursor-pointer group text-left w-full items-center hover:bg-[#F7F4EC] border-l-4': true,
    dashed: !isEditing && locked,
    editing: isEditing,
    last: isLast,
    'border-transparent': !active,
    'border-team bg-[#F7F4EC]': active,
  })

  const cn = classNames({
    'inline-block rounded-full mt-0.5 border-2 relative bg-white': true,
    'border-actions-multiple_choice': !isEditing && !locked,
    'border-gray-400': !isEditing && locked,
    'w-3 h-3': !active,
    'w-4 h-4': active,
  })

  const cs = classNames({
    'flex flex-1 ml-4 mt-4 mb-3 items-center': true,
    // 'pb-2 mb-3': !isLast,
  })

  const scrollTop = () => {
    const el = document.getElementById('learn-main')
    el?.scrollTo && el.scrollTo(0, 0)
  }

  const onClick = (e, type?) => {
    if (type === 'keyboard' && e.code !== 'Enter') return

    e.nativeEvent.preventDefault()

    if (isEditing || !locked) {
      const parsedStorage = JSON.parse(localStorage.getItem('currentChapters') || '{}')
      parsedStorage[courseId] = id
      localStorage.setItem('currentChapters', JSON.stringify(parsedStorage))
      updateAppCache('activeChapterId', id)
      scrollTop()
      // track({
      //   event: 'Session changed',
      //   variables: {
      //     onScreen: 'Learn',
      //   }
      // })
      return navigate(`/learn/${id}`)
    }

    if (!isEditing && locked && streak) {
      return showModal({
        title: intl.get('session_locked_title'),
        content: intl.get('streak_locked_text'),
        primaryText: intl.get('streak_locked_button') ,
        primaryAction: () => navigate('/learn'),
        secondaryText: intl.get('ok'),
      })
    }

    if (!isEditing && locked) {
      return showModal({
        title: intl.get('session_locked_title'),
        content: intl.get('session_locked_text'),
        secondaryButton: false,
        primaryText: intl.get('ok'),
      })
    }

    return false
  }

  const copyChapter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    localStorage.setItem('copiedChapter', JSON.stringify({ fromCourseId: courseId, fromChapterId: id, chapterTitle: title }))
    showAlert('Session copied!')
  }

  const [del] = useMutation<DeleteChapterRes, DeleteChapterVars>(deleteChapterMutation, {
    variables: { courseId, chapterId: id },
    onCompleted: () => {
      track({
        event: 'Session Deleted',
        variables: {
          programId: courseId,
        },
      })
    },
  })

  const delChapter = (e) => {
    e.preventDefault()
    e.stopPropagation()

    showModal({
      title: intl.get('chapter_tooltip_remove'),
      component: (
        <>
          <div>{intl.get('chapter_confirm_remove_text', { 0: order, 1: title })}</div>
          <div className="mt-4 text-red-600 font-bold">{intl.get('chapter_remove_input_popup_message', { 0: intl.get('delete').toUpperCase() })}</div>
        </>
      ),
      destructive: true,
      primaryText: intl.get('global_delete'),
      prompt: true,
      primaryAction: (r) => {
        if (!r || r !== 'DELETE') return toast.error('Please type DELETE (uppercase) to delete the session.')

        del({
          variables: {
            courseId,
            chapterId: id,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteChapter: {
              id,
              courseId,
              __typename: 'ChapterId',
            },
          },
          update: (proxy, { data }) => {
            const app: any = proxy.readQuery({ query: getAppStateQuery })
            const user: any = proxy.readQuery({
              query: getCurrentSubscriptionQuery,
              variables: {
                userId: app.appState.loggedInAs.uid,
              },
            })
            const cc = user.user.profile.currentSubscription
            const [companyId, courseId] = cc && cc.split('-') || ['', '']
            const d2: any = proxy.readQuery({
              query: getChaptersForCourseQuery,
              variables: {
                companyId,
                courseId,
                returnAllAssessments: true,
              },
            })
            if (!d2) return

            const chapter = d2.chapters.find(c => c.id === id)
            if (!chapter) return

            proxy.writeQuery({
              query: getChaptersForCourseQuery,
              variables: {
                companyId,
                courseId,
                returnAllAssessments: true,
              },
              data: produce(d2, (draft) => {
                draft.chapters = draft.chapters.filter(c => c.id !== id)
              }),
            })
          },
        })
      },
    })
  }

  return (
    <div id={`#${id}`} className={ci} onKeyDown={e => onClick(e, 'keyboard')} tabIndex={0}>
      <div className="flex flex-1 items-center mx-3" onClick={onClick}>
        <div className="w-4">
          {isEditing
            ? <span className="font-bold cursor-grab">
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z" fill="#101010"/>
                </svg>
              </span>
            : <div className={cn}>
                <div className={`w-full absolute ${!isEditing && locked ? '' : 'bg-actions-multiple_choice'} bottom-0 transition-all`} style={{ height: `${completion}%` }} />
              </div>
          }
        </div>

        <div className={cs}>
          <div className="flex-1">
            <div className={`${active ? undefined : !isEditing && locked ? 'text-gray-400' : 'text-deepgray'} uppercase tracking-wider text-sm font-medium`}>
              {intl.get('session_number', { 0: order })}
              {isEditing && locked && <i className="icon icon-lock-solid text-coral ml-2" />}
            </div>
            {title === 'Optimistic update' && <div className="animate-pulse h-4 w-5/6 bg-gray-200 rounded mt-1" />}
            {title !== 'Optimistic update' && <div className={`${active ? 'underline' : !isEditing && locked ? 'text-gray-400' : 'text-deepgray'} font-bold line-clamp-4 break-words`}>
              {title}
            </div>}
          </div>
        </div>
      </div>

      {isEditing && (
        <ChapterMenu order={order} copyChapter={copyChapter} delChapter={delChapter} />
      )}
    </div>
  )
}

const ChapterMenu = ({ order, copyChapter, delChapter }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom', 'right', 'top']}
      align="start"
      padding={10}
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <Transition
          show={true}
          appear={true}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="w-44 custom-dropdown z-10 text-sm">
            <div className="py-1">
              <Tippy content={intl.get('paste_session')} placement="right">
                <button
                  onClick={(e) => {
                    setIsOpen(false)
                    copyChapter(e)
                  }}
                  className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                  {intl.get('copy_session')}
                </button>
              </Tippy>

              <Tippy content="Can't delete the first session." disabled={order > 1} placement="bottom">
                <button
                  onClick={(e) => {
                    setIsOpen(false)
                    if (order <= 1) return
                    delChapter(e)
                  }}
                  className={classNames({
                    'text-gray-400': order <= 1,
                    'text-coral': order > 1,
                    'hover:bg-gray-100': order > 1,
                    'menu-item flex justify-between w-full px-4 py-1.5 text-sm': true,
                  })}>
                  {intl.get('chapter_tooltip_remove')}
                </button>
              </Tippy>
            </div>
          </div>
        </Transition>
      )}>
      <button className="flex items-center text-deepgray rounded-full hover:text-actions-multiple_choice focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-actions-multiple_choice mr-1" onClick={(e) => {
        setIsOpen(!isOpen)
      }}>
        <span className="sr-only">Open options</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
      </button>
    </Popover>
  )
}
