import { gql } from '@apollo/client'
import { timestampsFragment, timestampsUpdateFragment } from './Timestamps'

export const courseFragment = gql`
  ${timestampsFragment}

  fragment CourseFragment on Course {
    id
    title
    description
    titleEnglish
    tagline
    chaptersCount
    chaptersUnlocked
    curatorName
    curatorTitle
    curatorBio
    curatorPhotoUrl
    curatorSignatureDisabled
    curatorChatEnabled
    curatorCompany
    passingGrade
    imageUrl
    actionsCount
    interactionTime
    canRetakeActionAssessments
    maxActionAssessmentsRetakes
    showActionAssessmentsResultsPerAction
    denyContentCopy
    workbookDisabled
    allowMediaDownload
    language
    category
    categoryText
    difficulty
    skills
    cpeHours
    graduationGrade
    isTemplate
    tags
    type
    certSealUrl
    hasAssessments
    owner {
      id
      type
      organization {
        id
        name
      }
      user {
        id
        profile {
          id
          fullName
        }
      }
    }
    scormSettings {
      uploadStatus
    }
    timestamps {
      ...TimestampsFragment
    }
    testCompany {
      id
      accessCodes
    }
    manualReviews
    likesCount
    translatedToLanguage
  }
`

export const accessCodesFragment = gql`
  fragment AccessCodesFragment on Company {
    id
    accessCodes
  }
`

export const editorCourseFragment = gql`
  fragment EditorCourseFragment on Course {
    curatorSignatureDisabled
    category
    categoryText
    skills
    difficulty
    language
    titleEnglish
    cpeHours
  }
`

export const courseEmbedDataUpdatedFragment = gql`
  fragment CourseEmbedDataUpdatedFragment on CourseEmbedDataUpdated {
    courseId
    chaptersCount
    actionsCount
    interactionTime
  }
`

export const courseTimestampsUpdatedFragment = gql`
  ${timestampsUpdateFragment}

  fragment CourseTimestampsUpdatedFragment on CourseTimestampsUpdated {
    courseId
    timestamps {
      ...TimestampsUpdateFragment
    }
  }
`

export const courseBasicFragment = gql`
  fragment CourseFragmentBasic on Course {
    id
    title
    description
    tagline
    chaptersCount
    chaptersUnlocked
    curatorName
    curatorTitle
    curatorBio
    curatorPhotoUrl
    curatorChatEnabled
    curatorSignatureDisabled
    curatorCompany
    imageUrl
    actionsCount
    interactionTime
    allowMediaDownload
    type
    showActionAssessmentsResultsPerAction
    passingGrade
  }
`
