import { Disclosure, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/utils/Button'
import React, { useContext, useEffect, useState } from 'react'
import CheckGnowbeIcon from 'components/icons/CheckGnowbeIcon'
import EmptyCircleIcon from 'components/icons/EmptyCircleIcon'
import { useTour } from '@reactour/tour'
import { showModal } from 'components/utils/CustomModal'
import { hideMarketing, isAdmin, isAgentInAnyGroup, switchUserToFirstEditorCourse } from 'graphql/schemas/user/User'
import { OpenChecklistTourContext } from 'components/Layout'
import classNames from 'classnames'
import { updateAppCache } from 'app'
import { updateMeta, updateMetaDeep } from 'actions/users_actions'
import { getCompaniesAllowedToDistributeCourseToQuery, GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars } from 'graphql/queries/editor/getCompaniesAllowedToDistributeCourseTo'
import { useMutation, useQuery } from '@apollo/client'
import { AppState } from 'graphql/schemas/app/State'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getOrganizationsOrCmpsAllowedToPublishToQuery, OrganizationAllowPublishToRes } from 'components/course/Publish'
import Confetti from 'react-confetti'
import { ensureOrganizationForUserMutation, EnsureOrganizationForUserVars } from 'graphql/mutations/user/ensureOrganizationForUser'
import { track } from 'utils/track'
import { useLocation, useNavigate } from 'react-router-dom'

const ChecklistTour = ({ hideNavButtons, setPopoverHeight, user, isChecklistTourOpen, showChecklist, tourStep, setTourStep }) => {
  const [dismiss, setDismiss] = useState(false)
  const { isOpen, setIsOpen, steps, setSteps, currentStep, setCurrentStep } = useTour()
  const navigate = useNavigate()
  const location = useLocation()
  const [ensureOrganizationForUser] = useMutation<{ ensureOrganizationForUser: string }, EnsureOrganizationForUserVars>(ensureOrganizationForUserMutation)
  const [confettiCelebration, setConfettiCelebration] = useState(false)
  
  const firstSandbox = document.querySelector('.sandboxClassName')
  const firstNormal = document.querySelector('.normalClassName')
  const percentage = [12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100]
  const buttonCreateNewProgram = document.getElementById('create-program-button')
  const tourContext = useContext(OpenChecklistTourContext)
  const checklistBtn = classNames({
    'relative text-left disabled:cursor-not-allowed': true,
  })
  const checklistDiv = classNames({
    'ml-10': true,
  })

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const activeChapterId = appData?.appState.activeChapterId

  const { data, loading, refetch } = useQuery<GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars>(
    getCompaniesAllowedToDistributeCourseToQuery,
    {
      skip: !appData || !courseId,
      variables: {
        courseId,
        userId: user.id,
        searchText: '',
        skip: 0,
        limit: 100,
      },
      nextFetchPolicy: 'network-only',
    },
  )

  const { data: pubData, refetch: refetchPubData } = useQuery<OrganizationAllowPublishToRes, { courseId: string, userId: string }>(getOrganizationsOrCmpsAllowedToPublishToQuery, {
    skip: !appData || !courseId,
    variables: {
      courseId,
      userId: user.id,
    },
    nextFetchPolicy: 'network-only',
  })

  const [canPublish, setCanPublish] = useState<Boolean>(true)
  const [isNotAdminAgent, setIsNotAdminAgent] = useState<Boolean>(true)
  const [explainSanboxDefaultState, setExplainSanboxDefaultState] = useState(false)

  useEffect(
    () => {
      if (tourStep === 3 && !user.roles.includes('publisher') && !user.roles.includes('independentEditor')) {
        setCanPublish(false)
      }
    }, [tourStep],
  )
  useEffect(
    () => {
      if (tourStep === 4 && (!isAdmin(user) && !isAgentInAnyGroup(user))) {
        setIsNotAdminAgent(false)
      }
    }, [tourStep],
  )

  useEffect(() => {
    if (!explainSanboxDefaultState) return
    handleTour(explainSandboxDefault, 6)
  }, [explainSanboxDefaultState])

  const moveToStep1 = () => {
    setTimeout(() => {
      setCurrentStep(1)
    }, 1000)
  }
  const moveToStep2 = () => {
    setTimeout(() => {
      setCurrentStep(2)
    }, 1000)
  }
  const moveToStep3 = () => {
    setTimeout(() => {
      setCurrentStep(3)
    }, 1000)
  }
  const moveToStep4 = () => {
    setTimeout(() => {
      setCurrentStep(4)
    }, 1000)
  }
  const moveToStep5 = () => {
    setTimeout(() => {
      setCurrentStep(5)
    }, 1000)
  }
  const moveToStep6 = () => {
    setTimeout(() => {
      setCurrentStep(6)
    }, 1000)
  }

  const moveToStep7 = () => {
    setTimeout(() => {
      setCurrentStep(7)
    }, 1000)
  }

  const closeTour = () => {
    setIsOpen(false)
    setCurrentStep(0)
    isChecklistTourOpen(true)
  }

  const enterClick3 = (e) => {
    if (e.key === 'Enter') {
      moveToStep3()
    }
  }

  useEffect(
    () => {
      setConfettiCelebration(true)
      updateMeta('/newChecklistTour', `${tourStep}`, true)

    }, [tourStep],
  )

  useEffect(
    () => {
      if (user.metadata?.general?.newChecklistTour && Object.keys(user.metadata?.general.newChecklistTour).length === 0) {
        setTourStep(tourStep)
      } else if (user.metadata?.general?.newChecklistTour) {
        setTourStep(Number(user.metadata?.general.newChecklistTour))
      }
    }, [],
  )

  useEffect(
    () => {
      if (!!document.getElementById('is-editing-program') && isOpen && tourStep === 0) {
        setCurrentStep(3)
        setIsOpen(true)
      }
    }, [!!document.getElementById('is-editing-program')],
  )

  useEffect(
    () => {
      if (!isOpen && tourStep === 0) {
        buttonCreateNewProgram?.removeEventListener('click', moveToStep1)
        const createYourOwnLi = document.getElementById('create-your-own')
        createYourOwnLi?.removeEventListener('click', moveToStep2)
        const nameYourProgram = document.getElementById('name-your-program-create-button')
        nameYourProgram?.removeEventListener('click', moveToStep3)
      }
      if (!isOpen && tourStep === 1) {
        const addSessionButton = document.getElementById('button-add-session')
        addSessionButton?.removeEventListener('click', moveToStep2)
        const continueButton = document.getElementById('session-continue-button')
        continueButton?.removeEventListener('click', moveToStep3)
        const continueInput = document.getElementById('session-continue-input')
        continueInput?.removeEventListener('keydown', enterClick3)
        const cancelButton = document.getElementById('session-cancel-button')
        cancelButton?.removeEventListener('click', closeTour)
      }
      if (!isOpen && tourStep === 2) {
        const addNewActionButton = document.getElementById('add-new-action-button')
        addNewActionButton?.removeEventListener('click', moveToStep1)
        const textButtonType = document.getElementById('text-button-type')
        textButtonType?.removeEventListener('click', moveToStep2)
        const addAndEditButton = document.getElementById('add-and-edit-button')
        addAndEditButton?.removeEventListener('click', moveToStep3)
      }
      if (!isOpen && tourStep === 3) {
        const publisYourProgramButton = document.getElementById('publish-program-button')
        publisYourProgramButton?.removeEventListener('click', moveToStep1)
        const buttonGroup = document.getElementById('notAllowedToPublishToOrg-button')
        buttonGroup?.removeEventListener('click', moveToStep3)
        const toggleWrapper = document.getElementsByClassName('tgl-wrapper')[0]
        toggleWrapper?.removeEventListener('click', moveToStep3)
        toggleWrapper?.removeEventListener('click', moveToStep4)
      }
      if (!isOpen && tourStep === 4) {
        const firstDistributeCheckbox = document.getElementById('first-distrib-checkbox')
        firstDistributeCheckbox?.removeEventListener('click', moveToStep3)
        const distributeButton = document.getElementById('distribute-btn')
        distributeButton?.removeEventListener('click', moveToStep4)
        distributeButton?.removeEventListener('click', moveToStep7)
        const shareWithCreateGroupButton = document.getElementById('share-with-create-group-button')
        shareWithCreateGroupButton?.removeEventListener('click', moveToStep2)
        const createNewGroupButton = document.getElementById('create-new-group-button')
        const finishTheInviteButton = document.getElementById('finish-the-invite-button')
        createNewGroupButton?.removeEventListener('click', moveToStep3)
        finishTheInviteButton?.removeEventListener('click', moveToStep4)
        const cancelNewGroupButton = document.getElementById('cancel-new-group-button')
        cancelNewGroupButton?.removeEventListener('click', closeTour)
        firstDistributeCheckbox?.removeEventListener('click', moveToStep6)
      }
      if (!isOpen && tourStep === 5) {
        const threeDotButton = document.getElementById('first-distrib-actions')
        threeDotButton?.removeEventListener('click', moveToStep1)
        const inviteLearnersButton = document.getElementById('invite-learners-button')
        inviteLearnersButton?.removeEventListener('click', moveToStep1)
      }
    }, [isOpen],
  )

  const handleTour = (newSteps, tourStep: number) => {
    setSteps(newSteps)
    setTourStep(tourStep)
    const openTheTour = () => {
      setIsOpen(true)
    }

    if (currentStep === newSteps.length - 1) {
      setCurrentStep(0)
    }
    if (tourStep === 0 && location.pathname !== '/home') {
      navigate('/home')
      setTimeout(
        () => {
          openTheTour()
        }, 100,
      )
    }

    if (tourStep === 6 && location.pathname === '/home') {
      openTheTour()
    }

    if (tourStep === 0 && location.pathname === '/home') {
      openTheTour()
    }
    if (tourStep === 1 && (!location.pathname.includes('/details') || !location.pathname.includes(user.editorCourses.find(element => element === courseId)))) {
      const editableProgramModal = showModal({
        component: <div className="pt-4 pb-2">
          <img className="mx-auto" src="/images/add-session-modal-take-me-there.png" />
          <div className="font-medium text-2xl my-4">Choose an editable program!</div>
          <div>You need to be in an editable Program to proceed with this step.</div>
          <div className="my-2">Select <strong>“Programs”</strong> from the left menu, then look for a Program that’s <strong>“Curated by me”</strong>. Choose the one you want to proceed with and go into <strong>“Edit”</strong> mode - then launch this guide again!</div>
          <div>(Alternatively, click on the button below to go directly to a randomly-chosen editable Program!)</div>
          <div className="space-y-4 mt-4 text-center">
            <div>
              <Button type="primary" text="Take me there!" onClick={() => {
                const editorCourse = user.editorCourses[0]
                const sub = user.subscriptions.find(e => e.includes(editorCourse))
                switchUserToFirstEditorCourse(user, true, `/details/${sub}`)
                // const editorCourse = user.editorCourses[0]
                // if (!editorCourse) return
                // const sub = user.subscriptions.find(e => e.includes(editorCourse))
                // if (!sub) return
                // const [companyId, courseId] = sub.split('-')
                // changeCurrentCourse({
                //   courseKey: {
                //     companyId,
                //     courseId,
                //   },
                //   enableEditing: true,
                //   landing: `/details/${sub}`,
                // })
                editableProgramModal.close()
                updateAppCache('isEditing', 'true')
                openTheTour()
              }
              } />
            </div>
          </div>
        </div>,
        onlyContent: true,
      })
    }
    if (tourStep === 1 && location.pathname.includes('/details') && location.pathname.includes(user.editorCourses.find(element => element === courseId))) {
      openTheTour()
    }
    if (tourStep === 2 && ((location.pathname !== `/learn/${activeChapterId}` && location.pathname !== '/learn') || !user.editorCourses.includes(courseId))) {

      const editableProgramModal = showModal({
        component: <div className="pt-4 pb-2">
          <img className="mx-auto" src="/images/add-action-modal-take-me-there.png" />
          <div className="font-medium text-2xl my-4">Choose an editable program!</div>
          <div>You need to be in an editable Program to proceed with this step.</div>
          <div className="my-2">Navigate to a Program you’ve curated and enter <strong>“Edit”</strong> mode. While in Edit mode, click on any of the <strong>sessions</strong> - then launch this guide again!</div>
          <div>(Alternatively, click on the button below to go directly to a randomly-chosen editable Program!)</div>
          <div className="space-y-4 mt-4 text-center">
            <div>
              <Button type="primary" text="Take me there!" onClick={() => {
                switchUserToFirstEditorCourse(user, true, '/learn')
                // const editorCourse = user.editorCourses[0]
                // if (!editorCourse) return
                // const sub = user.subscriptions.find(e => e.includes(editorCourse))
                // if (!sub) return
                // const [companyId, courseId] = sub.split('-')
                // changeCurrentCourse({
                //   courseKey: {
                //     companyId,
                //     courseId,
                //   },
                //   enableEditing: true,
                //   landing: '/learn',
                // })
                editableProgramModal.close()
                setTimeout(
                  () => {
                    updateAppCache('isEditing', 'true')
                  }, 0)
                setTimeout(
                  () => {
                    openTheTour()
                  }, 1000)
              }
              } />
            </div>
          </div>
        </div>,
        onlyContent: true,
      })
    }
    if (tourStep === 2 && (location.pathname === `/learn/${activeChapterId}` || location.pathname === '/learn') && user.editorCourses.includes(courseId)) {
      openTheTour()
    }
    if (tourStep === 3 && ((!location.pathname.includes('/learn') && !location.pathname.includes('/details')) || !user.editorCourses.includes(courseId))) {
      const editableProgramModal = showModal({
        title: 'Choose an editable program!',
        component: <div className="pt-4 pb-2">
          <img className="mx-auto" src="/images/add-action-modal-take-me-there.png" />
          <div className="font-medium text-2xl my-4">Choose an editable program!</div>
          <div>You need to be in an editable Program to proceed with this step.</div>
          <div className="my-2">Navigate to a Program you’ve curated and enter <strong>“Edit”</strong> mode. While in Edit mode, click on any of the <strong>sessions</strong> - then launch this guide again!</div>
          <div>(Alternatively, click on the button below to go directly to a randomly-chosen editable Program!)</div>
          <div className="space-y-4 mt-4 text-center">
            <div>
              <Button type="primary" text="Take me there!" onClick={() => {
                ensureOrganizationForUser({
                  variables: {
                    userId: user.id,
                  },
                  onCompleted: () => refetchPubData(),
                })
                switchUserToFirstEditorCourse(user, true, '/learn')
                editableProgramModal.close()
                setTimeout(
                  () => {
                    updateAppCache('currentTour', 'checkListTour')
                    updateAppCache('isEditing', 'true')
                  }, 0)
                setTimeout(
                  () => {
                    openTheTour()
                  }, 1000)
              }
              } />
            </div>
          </div>
        </div>,
        onlyContent: true,
      })
    }
    if (tourStep === 3 && (location.pathname.includes('/learn') || location.pathname.includes('/details')) && user.editorCourses.includes(courseId)) {
      ensureOrganizationForUser({
        variables: {
          userId: user.id,
        },
        onCompleted: () => refetchPubData(),
      })
      updateAppCache('currentTour', 'checkListTour')
      openTheTour()
    }
    if ((tourStep === 4 || tourStep === 5) && (!location.pathname.includes('/home/publish') || location.hash !== '#share')) {
      const editableProgramModal = showModal({
        title: 'Choose an editable program!',
        component: <div className="pt-4 pb-2">
          <img className="mx-auto" src="/images/publish-program-modal-take-me-there.png" />
          <div className="font-medium text-2xl my-4">Choose an editable program!</div>
          <div>You need to be in an editable Program to proceed with this step.</div>
          <div className="my-2">Navigate to a Program that you have curated and enter <strong>“Edit”</strong> mode. While in Edit mode, click on <strong>“Share”</strong> on the top right - then launch this guide again!</div>
          <div>(Alternatively, click on the button below to go directly to a randomly-chosen editable Program!)</div>
          <div className="space-y-4 mt-4 text-center">
            <div>
              <Button type="primary" text="Take me there!" onClick={() => {
                editableProgramModal.close()
                updateAppCache('currentTour', 'checkListTour')
                navigate('/home/publish#share')
                setTimeout(
                  () => {
                    openTheTour()
                  }, 250)
              }
              } />
            </div>
          </div>
        </div>,
        onlyContent: true,
      })
    }
    if ((tourStep === 4 || tourStep === 5) && (location.pathname.includes('/home/publish') && location.hash === '#share')) {
      openTheTour()
    }
  }

  const showLearnWhyModal = () => {
    isChecklistTourOpen(false)
    const modal = showModal({
      title: 'You are unable to perform this action.',
      component: (
        <div>
          <p>
            Oops! You do not have the relevant permissions to perform this action. Only users with Creator roles are able to share a program and add learners.
            <br />
            <br />
            If you receive a <a href="https://gnowbe.zendesk.com/hc/en-us/articles/360010671994-What-is-the-difference-between-the-three-Creator-roles-Admin-Agent-and-Curator-" className="underline font-bold text-coral">Creator role</a> in future, you can return to this step of the guided tutorial to learn more.
          </p>
        </div>
      ),
      primaryText: 'Close',
      primaryAction: () => {
        isChecklistTourOpen(true)
        modal.close()
      },
      secondaryButton: false,
    })
  }
  const divClassname = 'space-y-2'

  const createProgramTourSteps = [
    {
      selector: '#create-program-button',
      content: 'Click here to start.',
      stepInteraction: true,
      action: () => {
        setPopoverHeight('small')
        buttonCreateNewProgram?.addEventListener('click', moveToStep1)
        hideNavButtons(true)
      },
      actionAfter: () => {
        buttonCreateNewProgram?.removeEventListener('click', moveToStep1)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#create-your-own',
      content: (
        <div className={divClassname}>
          <strong>Choose a program type</strong>
          <p>You can select different program types to best suit what you need to create. For this tutorial, let’s select “Create your own”.</p>
          <br />
          <br />
          <strong>Click on “Create your own” now.</strong>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(true)
        setPopoverHeight('big')
        setTimeout(() => {
          const createYourOwnLi = document.getElementById('create-your-own')
          createYourOwnLi?.addEventListener('click', moveToStep2)
        }, 250)
      },
      actionAfter: () => {
        const createYourOwnLi = document.getElementById('create-your-own')
        createYourOwnLi?.removeEventListener('click', moveToStep2)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#name-your-program',
      content: (
        <div className={divClassname}>
          <strong>Choose a program title</strong>
          <p>Let's make a practice program. <br></br> What would you like to title it? <br></br> Proceed by clicking “Create program”.</p>
        </div>
      ),
      position: 'bottom',
      stepInteraction: true,
      action: () => {
        hideNavButtons(true)
        setPopoverHeight('medium')
        setTimeout(() => {
          const nameYourProgram = document.getElementById('name-your-program-create-button')
          nameYourProgram?.addEventListener('click', moveToStep3)
        }, 1000)
      },
      actionAfter: () => {
        hideNavButtons(false)
        const nameYourProgram = document.getElementById('name-your-program-create-button')
        nameYourProgram?.removeEventListener('click', moveToStep3)
      },
    },
    {
      selector: '#is-editing-program',
      content: (
        <div className={divClassname}>
          <strong>Edit and customize your program details</strong>
          <p>This program details page is what your users see before they start your program, so be sure to make it pop!
            <br />
            <br />
            You can add an image, give your program a tagline, and write a program description.
            <br />
            <br />
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('biggest')
        setIsOpen(true)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    }]

  const addSessionToProgramSteps = [
    {
      selector: '.new-chapter-item',
      content: (
        <div>
          <p>If a program is a book, the sessions are the chapters! Every program contains at least one session.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#button-add-session',
      content: (
        <div className={divClassname}>
          <strong>Add sessions to your program</strong>
          <p>You can add more sessions with this “Add session” button. On paid plans, you’ll also be able to import PowerPoint files as Gnowbe sessions.
            <br />
            <br />
            <strong>Click on “Add session” to continue.</strong></p>
        </div>
      ),
      position: 'top',
      padding: {
        popover: 42,
      },
      stepInteraction: true,
      action: () => {
        setPopoverHeight('big')
        hideNavButtons(true)
        setTimeout(() => {
          const addSessionButton = document.getElementById('button-add-session')
          addSessionButton?.addEventListener('click', moveToStep2)
        }, 100)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const addSessionButton = document.getElementById('button-add-session')
        addSessionButton?.removeEventListener('click', moveToStep2)
      },
    },
    {
      selector: '.stick-container',
      content: (
        <div className={divClassname}>
          <strong>Customize your sessions</strong>
          <p>This is where you edit the settings for each session, as well as add a description of what learners will learn in this session, and learning objectives.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('big')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#chapter_daily_messaging',
      content: (
        <div className={divClassname}>
          <p>Go here to send an email or push notification to your learners when they get to this session.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#chapter_toggle_assessment_mode',
      content: (
        <div className={divClassname}>
          <p>Turn this on to turn this session into a quiz or assessment.
            <br />
            <br />
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]

  const customizeYourOwnActionSteps = [
    {
      selector: '#add-new-action-button',
      content: (
        <div>
          <p>Actions are activities (e.g. video, poll, question, etc.) that you add to each session.
            <br />
            <br />
            <strong>Click on “Add a new action” to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const addNewActionButton = document.getElementById('add-new-action-button')
        addNewActionButton?.addEventListener('click', moveToStep1)
        hideNavButtons(true)
        setPopoverHeight('small')
      },
      actionAfter: () => {
        const addNewActionButton = document.getElementById('add-new-action-button')
        addNewActionButton?.removeEventListener('click', moveToStep1)
        setPopoverHeight(undefined)
      },
      position: 'top',
    },
    {
      selector: '#text-button-type',
      content: (
        <div>
          <p>You can select different types of interactive actions to populate your session.
            <br />
            <br />
            For this guide, please select <strong>“Text”</strong> as your first action.
            <br />
            <br />
            <strong>Click on “Text” to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const textButtonType = document.getElementById('text-button-type')
        textButtonType?.addEventListener('click', moveToStep2)
        hideNavButtons(true)
        setPopoverHeight('big')
      },
      actionAfter: () => {
        const textButtonType = document.getElementById('text-button-type')
        textButtonType?.removeEventListener('click', moveToStep2)
      },
    },
    {
      selector: '#add-and-edit-button',
      content: (
        <div>
          <p>Once you’ve selected your action type, you can add and edit it. If you’d rather add actions in bulk, you can also choose to “Add and keep adding”.
            <br />
            <br />
            <strong>Click on “Add and edit” to continue.</strong></p>
        </div>
      ),
      position: 'left',
      stepInteraction: true,
      action: () => {
        const addAndEditButton = document.getElementById('add-and-edit-button')
        addAndEditButton?.addEventListener('click', moveToStep3)
        hideNavButtons(true)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        const addAndEditButton = document.getElementById('add-and-edit-button')
        addAndEditButton?.removeEventListener('click', moveToStep3)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#three_icons_div',
      content: (
        <div>
          <p>Click on an icon to add a pop up transition between actions, an automatic email to be triggered on completion of the action, or a rating that can be tracked and measured from the dashboard.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      stepInteraction: false,
      action: () => {
        setPopoverHeight('big')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#menu_save_view',
      content: (
        <div>
          <p>Click save and view at any point to see what your program looks like from your learner's perspective, and then click edit to go back into edit mode.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      stepInteraction: false,
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#save_and_close',
      content: (
        <div>
          <p>Click here once you are done to return to your sessions.
            <br />
            <br />
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      stepInteraction: false,
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]



  const publishYourProgramToGroupOnlySteps = [
    {
      selector: '#publish-program-button',
      content: (
        <div>
          <p>Once your program is ready, you can make it available for sharing.
            <br />
            <br />
            <strong>Click on “Share” to begin.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const publisYourProgramButton = document.getElementById('publish-program-button')
        publisYourProgramButton?.addEventListener('click', moveToStep1)
        hideNavButtons(true)
      },
      actionAfter: () => {
        const publisYourProgramButton = document.getElementById('publish-program-button')
        publisYourProgramButton?.removeEventListener('click', moveToStep1)
      },
    },
    {
      selector: '#manage-program-availability',
      content: (
        <div>
          <p>Once you are done with your program, you may share it with multiple workspaces. Select all the workspaces you want to have access to your program here.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
    },
    {
      selector: '#notAllowedToPublishToOrg-button',
      content: (
        <div>
          <p>Open the group to be able to share your program.
            <br />
            <br />
            <strong>Click on the Group Name</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const buttonGroup = document.getElementById('notAllowedToPublishToOrg-button')
        buttonGroup?.addEventListener('click', moveToStep3)
        hideNavButtons(true)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        const buttonGroup = document.getElementById('notAllowedToPublishToOrg-button')
        buttonGroup?.removeEventListener('click', moveToStep3)
      },
    },
    {
      selector: '.tgl-wrapper',
      content: (
        <div>
          <p>This toggle gives permission for anyone else within the workspace to distribute the program to other groups.
            <br />
            <br />
            <strong>Click on the toggle to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const toggleWrapper = document.getElementsByClassName('tgl-wrapper')[0]
        toggleWrapper?.addEventListener('click', moveToStep4)
        hideNavButtons(true)
      },
      actionAfter: () => {
        const toggleWrapper = document.getElementsByClassName('tgl-wrapper')[0]
        toggleWrapper?.removeEventListener('click', moveToStep4)
      },
    },
    {
      selector: '#manage-program-availability',
      content: (
        <div>
          <p>Cool! Now other users (with the relevant permissions) are also able to distribute your program to other groups!
            <br />
            <br />
            Learn how to create groups of learners and share your program with them in the next part of this guide.
            <br />
            <br />
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('biggest')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]
  const publishYourProgramSteps = [
    {
      selector: '#publish-program-button',
      content: (
        <div>
          <p>Once your program is ready, you can make it available for sharing.
            <br />
            <br />
            <strong>Click on “Share” to begin.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const publisYourProgramButton = document.getElementById('publish-program-button')
        publisYourProgramButton?.addEventListener('click', moveToStep1)
        hideNavButtons(true)
      },
      actionAfter: () => {
        const publisYourProgramButton = document.getElementById('publish-program-button')
        publisYourProgramButton?.removeEventListener('click', moveToStep1)
      },
    },
    {
      selector: '#manage-program-availability',
      content: (
        <div>
          <p>Once you are done with your program, you may share it with multiple workspaces. Select all the workspaces you want to have access to your program here.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
    },
    {
      selector: '.tgl-wrapper',
      content: (
        <div>
          <p>This toggle gives permission for anyone else within the workspace to distribute the program to other groups.
            <br />
            <br />
            <strong>Click on the toggle to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const toggleWrapper = document.getElementsByClassName('tgl-wrapper')[0]
        toggleWrapper?.addEventListener('click', moveToStep3)
        hideNavButtons(true)
      },
      actionAfter: () => {
        const toggleWrapper = document.getElementsByClassName('tgl-wrapper')[0]
        toggleWrapper?.removeEventListener('click', moveToStep3)
      },
    },
    {
      selector: '#manage-program-availability',
      content: (
        <div>
          <p>Cool! Now other users (with the relevant permissions) are also able to distribute your program to other groups!
            <br />
            <br />
            Learn how to create groups of learners and share your program with them in the next part of this guide.
            <br />
            <br />
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('biggest')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]

  const shareYourProgramStepsAllChecked = [
    {
      selector: '#share-your-program',
      content: (
        <div>
          <p>Here you can add all the groups that you want to go through this program. Each group will have its own reporting, and the learners in that group will only interact with each other.
            <br></br>You can distribute the program to as many groups as you want!
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('biggest')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#share-with-create-group-button',
      content: (
        <div>
          <p>If you don’t already have a group, you can create one by clicking on the <strong>“Create Group”</strong> button.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#first-distrib-checkbox',
      content: (
        <div>
          <p> Check the group that you want to share this program to. You can select multiple groups at once.
            <br />
            <br />
            <strong>Click on the checkbox to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const firstDistributeCheckbox = document.getElementById('first-distrib-checkbox')
        firstDistributeCheckbox?.addEventListener('click', moveToStep3)
        hideNavButtons(true)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        const firstDistributeCheckbox = document.getElementById('first-distrib-checkbox')
        firstDistributeCheckbox?.removeEventListener('click', moveToStep3)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#distribute-btn',
      content: (
        <div>
          <p>Don’t forget to click on <strong>“Save changes”</strong> when you’re done!
            <br></br>
            <br></br>
            <strong>Click it now to share your program to the selected group after the confirmation.</strong> You can always unshare it later.
          </p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(true)
        setPopoverHeight('big')
        const distributeButton = document.getElementById('distribute-btn')
        distributeButton?.addEventListener('click', moveToStep4)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const distributeButton = document.getElementById('distribute-btn')
        distributeButton?.removeEventListener('click', moveToStep4)
      },
    },
    {
      selector: '#set-a-title-modal',
      content: (
        <div>
          <p>After changes are made you'll be prompted to confirm them.
            <br></br>
            <br></br>
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('big')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]
  const shareYourProgramSteps = [
    {
      selector: '#share-your-program',
      content: (
        <div>
          <p>Here you can add all the groups that you want to go through this program. Each group will have its own reporting, and the learners in that group will only interact with each other.
            <br></br>You can distribute the program to as many groups as you want!
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('biggest')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#share-with-create-group-button',
      content: (
        <div>
          <p>If you don’t already have a group, you can create one by clicking on the <strong>“Create Group”</strong> button.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#first-distrib-checkbox',
      content: (
        <div>
        <p> Make sure the group you want to share this program to is selected. You can select multiple groups at once.
          <br />
          After changes are made you'll be prompted to confirm them so be sure to save changes before leaving this page.
          <br />
          You can also unselect groups that you have distributed your program to in order to remove access.
          <br />
          <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
      </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('biggest')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },
  ]

  const shareYourProgramStepsWithoutGroup = [
    {
      selector: '#share-your-program',
      content: (
        <div>
          <p>Your program is ready to be shared to various groups of learners. Let’s learn how this can be done.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#share-with-create-group-button',
      content: (
        <div>
          <p> As you don’t already have a group, you will create one by clicking on the “Create Group” button.
            <br />
            <br />
            <strong>Click on “Create Group” to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(true)
        const shareWithCreateGroupButton = document.getElementById('share-with-create-group-button')
        shareWithCreateGroupButton?.addEventListener('click', moveToStep2)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const shareWithCreateGroupButton = document.getElementById('share-with-create-group-button')
        shareWithCreateGroupButton?.removeEventListener('click', moveToStep2)
      },
    },
    {
      selector: '#set-a-title-modal',
      content: (
        <div>
          <p>
            <br />
            <br />
            <strong>After naming your group click on “Create and Continue” to advance to the next step.</strong></p>
        </div>
      ),
      position: 'top',
      stepInteraction: true,
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(true)
        const createNewGroupButton = document.getElementById('create-new-group-button')
        const cancelNewGroupButton = document.getElementById('cancel-new-group-button')
        createNewGroupButton?.addEventListener('click', moveToStep3)
        cancelNewGroupButton?.addEventListener('click', closeTour)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const createNewGroupButton = document.getElementById('create-new-group-button')
        const cancelNewGroupButton = document.getElementById('cancel-new-group-button')
        createNewGroupButton?.removeEventListener('click', moveToStep3)
        cancelNewGroupButton?.removeEventListener('click', closeTour)
      },
    },
    {
      selector: '#set-a-title-modal',
      content: (
        <div>
          <p>Here you can directly invite users to your newly created group to which the program will be shared to. More on that on the Invite Learners tour.
            <br />
            <br />
            <strong>Click on "Finish" to close this modal.</strong></p>
        </div>
      ),
      position: 'top',
      stepInteraction: true,
      action: () => {
        setTimeout(() => {
          setPopoverHeight('medium')
          hideNavButtons(true)
          const finishTheInviteButton = document.getElementById('finish-the-invite-button')
          finishTheInviteButton?.addEventListener('click', moveToStep4)
        }, 1000)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const finishTheInviteButton = document.getElementById('finish-the-invite-button')
        const cancelNewGroupButton = document.getElementById('cancel-new-group-button')
        finishTheInviteButton?.removeEventListener('click', moveToStep4)
        cancelNewGroupButton?.removeEventListener('click', closeTour)
      },
    },

    {
      selector: '#all-groups-checkbox',
      content: (
        <div>
          <p> Check all the groups that you want to share this program to. You can select multiple groups at once.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#first-distrib-checkbox',
      content: (
        <div>
          <p> Check the group that you want to share this program to. You can select multiple groups at once.
            <br />
            <br />
            <strong>Click on the checkbox to continue.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(true)
        setPopoverHeight('medium')
        setTimeout(() => {
          const firstDistributeCheckbox = document.getElementById('first-distrib-checkbox')
          firstDistributeCheckbox?.addEventListener('click', moveToStep6)
        }, 250)
      },
      actionAfter: () => {
        const firstDistributeCheckbox = document.getElementById('first-distrib-checkbox')
        firstDistributeCheckbox?.removeEventListener('click', moveToStep6)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#distribute-btn',
      content: (
        <div>
          <p>Don’t forget to click on <strong>“Save changes”</strong> when you’re done!
            <br></br>
            <br></br>
            <strong>Click it now to share your program to the selected group after the confirmation.</strong> You can always unshare it later.
          </p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(true)
        setPopoverHeight('big')
        const distributeButton = document.getElementById('distribute-btn')
        distributeButton?.addEventListener('click', moveToStep7)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        const distributeButton = document.getElementById('distribute-btn')
        distributeButton?.removeEventListener('click', moveToStep7)
      },
    },
    {
      selector: '#set-a-title-modal',
      content: (
        <div>
          <p>After changes you'll be prompted to confirm them.
            <br></br>
            <br></br>
            <strong>Click on "Got it!" to complete this section of the guided tour.</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('big')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
        setCurrentStep(0)
      },
    },

  ]


  const getLearnerSteps = [
    {
      selector: '#invite-learners-button',
      content: (
        <div>
          <p>Please note that any learners added to this group will get access to all the programs that have been distributed to this group.
            <br />
            <br />
            <strong>Click on “+ Invite” button to continue.</strong>
          </p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        const inviteLearnersButton = document.getElementById('invite-learners-button')
        inviteLearnersButton?.addEventListener('click', moveToStep1)
        hideNavButtons(true)
        setPopoverHeight('medium')
      },
      actionAfter: () => {
        const inviteLearnersButton = document.getElementById('invite-learners-button')
        inviteLearnersButton?.removeEventListener('click', moveToStep1)
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#set-a-title-modal',
      position: 'left',
      content: (
        <div>
          <p>You have two ways of inviting someone to your group: <strong>Invite via email</strong> or by <strong>share link</strong>. 
            <br />
            Be aware that sharing a link lets anyone on the internet that gets access to the link view all the programs in the group.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong></p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('big')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: '#invite-by-email-div',
      content: (
        <div>
          <p>To invite learners, you can either add their email here, or copy the link below and share it with them.
            <br />
            <br />
            <strong>If you are ready to share this program, you can do that now.</strong>
            <br />
            <br />
            <strong>If not, you can click on "Got it!" to complete this section of the guided tour</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('biggest')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
  ]

  const explainSandboxDefault = [
    {
      selector: firstSandbox,
      content: (
        <div>
          <p>The sandbox version of your program is where you can edit and test your program. Any edits you make to the program will be applied to all groups.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong>
          </p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        setPopoverHeight('medium')
        hideNavButtons(false)
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: firstNormal,
      position: 'left',
      content: (
        <div>
          <p>Everytime you distribute your program to a group that you are part of as a learner, you will see a new program card with the group name below it. 
            <br />
            This is where you can go through the program as a learner with your group. 
            <br />
            Please note that if you make an edit to the program from a group, that edit will apply to all groups as well.
            <br />
            <br />
            <strong>Click on “Next” to continue.</strong>
          </p>
        </div>
      ),
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('biggest')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
    {
      selector: document.querySelector('[aria-label="Dashboard"]'),
      content: (
        <div>
          <p>Go here to see learner data for all your groups, send push notifications, mass distribute to groups and learners and more
            <br />
            <br />
            <strong>If not, you can click on "Got it!" to end your tour! Great job!</strong></p>
        </div>
      ),
      stepInteraction: true,
      action: () => {
        hideNavButtons(false)
        setPopoverHeight('big')
      },
      actionAfter: () => {
        setPopoverHeight(undefined)
      },
    },
  ]

  return (
    (user.allowCourseCreation === true) && !hideMarketing(user) && user.metadata?.general?.tour?.gnowbeChecklistTour === 'false' ? <div className="hidden lg:inline-block absolute bottom-3.5 right-36 z-20">
      <Disclosure
      >
        {({ open, close }) => (
          <div>
            <Disclosure.Button
              onClick={() => {
                if (tourContext.isChecklistTourOpen) {
                  tourContext.setIsChecklistTourOpen(false)
                  track({
                    event: 'Checklist Closed',
                    variables: {
                      tour_step: tourStep,
                      user_id: user.id,
                    },
                  })
                }
                if (!tourContext.isChecklistTourOpen) {
                  tourContext.setIsChecklistTourOpen(true)
                  setConfettiCelebration(false)
                  track({
                    event: 'Checklist Opened',
                    variables: {
                      user_id: user.id,
                    },
                  })
                }
              }}
              className={`${tourContext.isChecklistTourOpen ? 'rounded-t-lg' : 'rounded-lg'} collapsible-header text-md text-white w-full p-4 focus:outline-none focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-team bg-[#1B585E] h-[46px]`}>
              Getting Started with Gnowbe
              <ChevronUpIcon
                className={`${tourContext.isChecklistTourOpen ? 'rotate-180' : ''
                  } w-5 h-5 text-white ml-16`}
              />
            </Disclosure.Button>

            <Transition
              show={tourContext.isChecklistTourOpen}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0">
              <Disclosure.Panel className="px-6 bg-white rounded-b-lg max-w-[332px] lg:h-[400px] lg:overflow-y-auto xl:h-auto xl:overflow-y-hidden">
                {(!dismiss && tourStep !== 7 && canPublish && isNotAdminAgent) && <>
                  {(confettiCelebration && tourStep !== 0) && <Confetti recycle={false} height={500} gravity={0.35} width={332} onConfettiComplete={() => setConfettiCelebration(false)} />}
                  <div className="pt-4 mb-5 text-sm">
                    <div>
                      Go through the program creation by following the steps below.
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className={'w-10/12 h-2 bg-lightwarmgray rounded'}>
                      <div className={`${tourStep === 0 ? 'w-[12.5%]' : tourStep === 1 ? 'w-[25%]' : tourStep === 2 ? 'w-[37.5%]' : tourStep === 3 ? 'w-[50%]' : tourStep === 4 ? 'w-[62.5%]' : tourStep === 5 ? 'w-[75%]' : tourStep === 6 ? 'w-[87.5%]' : 'w-full'} rounded bg-lake h-2`}></div>
                    </div>
                    <div className="w-2/12 text-sm">{percentage[tourStep]} %</div>
                  </div>
                  <div className="border-b -mx-6 pb-5 h-"></div>
                  <div className="flex mt-4">
                    <CheckGnowbeIcon className="mr-5" />
                    <div>
                      <p className="line-through text-lightgray font-bold p-0">Set up your account</p>
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={tourStep !== 0}
                      onClick={() => {
                        handleTour(createProgramTourSteps, 0)
                        isChecklistTourOpen(false)
                        close()
                        track({
                          event: 'Checklist Started',
                          variables: {
                            tour_step: tourStep,
                            user_id: user.id,
                          },
                        })
                      }}>
                      {tourStep > 0 ? <CheckGnowbeIcon className={`absolute ${tourStep === 1 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className="absolute bottom-1/2 w-5 h-5" />}
                      <div className={checklistDiv}>
                        <p
                          className={`${tourStep > 0 ? 'line-through text-lightgray' : tourStep === 0 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0`}>
                          Create a new program
                        </p>
                        {tourStep === 0 && <p className="max-w-[220px]">Let’s dive into the limitless world of program creation!</p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={tourStep !== 1}
                      onClick={() => {
                        if (location.pathname.includes(user.editorCourses.find(element => element === courseId))) {
                          updateAppCache('isEditing', 'true')
                        }
                        handleTour(addSessionToProgramSteps, 1)
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 1 ? <CheckGnowbeIcon className={`absolute ${tourStep === 2 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 1 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 1 ? 'line-through text-lightgray' : tourStep === 1 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0`}>
                          Add a session to your program
                        </p>
                        {tourStep === 1 && <p className="max-w-[220px]">Add some structure! Lay out sessions for your program</p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={tourStep !== 2}
                      onClick={() => {
                        if (user.editorCourses.includes(courseId)) {
                          updateAppCache('isEditing', 'true')
                        }
                        handleTour(customizeYourOwnActionSteps, 2)
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 2 ? <CheckGnowbeIcon className={`absolute ${tourStep === 3 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 2 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 2 ? 'line-through text-lightgray' : tourStep === 2 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0`}
                        >Customize your own action</p>
                        {tourStep === 2 && <p className="max-w-[220px]">Unleash your creativity! Customize actions and make them your own.</p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={!user.roles.includes('publisher') && !user.roles.includes('independentEditor') || tourStep !== 3}
                      onClick={() => {
                        if (user.editorCourses.includes(courseId)) {
                          updateAppCache('isEditing', 'true')
                        }
                        if (pubData?.res.some(a => !a.allowedToPublishToOrg)) {
                          updateAppCache('currentTour', 'checkListTour')
                          handleTour(publishYourProgramToGroupOnlySteps, 3)
                        }
                        if (pubData?.res.some(a => a.allowedToPublishToOrg)) {
                          updateAppCache('currentTour', 'checkListTour')
                          handleTour(publishYourProgramSteps, 3)
                        }
                        if (pubData?.res.length === 0) {
                          updateAppCache('currentTour', 'checkListTour')
                          handleTour(publishYourProgramSteps, 3)
                        }
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 3 ? <CheckGnowbeIcon className={`absolute ${tourStep === 4 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 3 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 3 ? 'line-through text-lightgray' : tourStep === 3 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0`}
                        >Share your program</p>
                        {tourStep === 3 && <p className="max-w-[220px]">You’ve got the power! Control who gets to make your program available for sharing.</p>}
                        {(!user.roles.includes('publisher') && !user.roles.includes('independentEditor')) && <p className="text-coral text-xs">You cannot perform this action. <span onClick={showLearnWhyModal} className="font-bold text-xm underline cursor-pointer">Learn why.</span></p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={(!isAdmin(user) && !isAgentInAnyGroup(user)) || tourStep !== 4}
                      onClick={() => {
                        if (!data) return null
                        if (!!document.getElementById('distribute-btn')) {
                          const modal = showModal({
                            title: 'Please save changes before continue',
                            component: (
                              <div>
                                You have unsaved changes, please save them before proceeding
                              </div>
                            ),
                            primaryText: 'Close',
                            primaryAction: () => {
                              isChecklistTourOpen(true)
                              modal.close()
                            },
                            secondaryButton: false,
                          })
                          return null
                        }
                        if (data.res.companiesCount > 0) {
                          updateAppCache('currentTour', 'checkListTour')
                          handleTour(shareYourProgramSteps, 4)
                        }
                        if (data.res.companiesCount === 0) {
                          updateAppCache('currentTour', 'checkListTour')
                          handleTour(shareYourProgramStepsWithoutGroup, 4)
                        }
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 4 ? <CheckGnowbeIcon className={`absolute ${tourStep === 5 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 4 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 4 ? 'line-through text-lightgray' : tourStep === 4 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0 ${(!isAdmin(user) && !isAgentInAnyGroup(user)) && 'cursor-not-allowed'}`}
                        >Add your program to a group</p>
                        {tourStep === 4 && <p className="max-w-[220px]">Almost there! Share your program with groups of learners!</p>}
                        {(!isAdmin(user) && !isAgentInAnyGroup(user)) && <p className="text-coral text-xs">You cannot perform this action. <span onClick={showLearnWhyModal} className="font-bold text-xm underline cursor-pointer">Learn why.</span></p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={(!isAdmin(user) && !isAgentInAnyGroup(user)) || tourStep !== 5}
                      onClick={() => {
                        if (!!document.getElementById('distribute-btn')) {
                          const modal = showModal({
                            title: 'Please save changes before continue',
                            component: (
                              <div>
                                You have unsaved changes, please save them before proceeding with this tour.
                              </div>
                            ),
                            primaryText: 'Close',
                            primaryAction: () => {
                              isChecklistTourOpen(true)
                              modal.close()
                            },
                            secondaryButton: false,
                          })
                          return null
                        }
                        updateAppCache('currentTour', 'checkListTour')
                        handleTour(getLearnerSteps, 5)
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 5 ? <CheckGnowbeIcon className={`absolute ${tourStep === 6 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 5 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 5 ? 'line-through text-lightgray' : tourStep === 5 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0 ${(!isAdmin(user) && !isAgentInAnyGroup(user)) && 'cursor-not-allowed'}`}
                        >Invite some users</p>
                        {tourStep === 5 && <p className="max-w-[220px]">Invite others to go through your program!</p>}
                        {(!isAdmin(user) && !isAgentInAnyGroup(user)) && <p className="text-coral text-xs">You cannot perform this action. <span onClick={showLearnWhyModal} className="font-bold text-xm underline cursor-pointer">Learn why.</span></p>}
                      </div>
                    </button>
                  </div>

                  <div className="flex mt-4">
                    <button className={checklistBtn}
                      disabled={(!isAdmin(user) && !isAgentInAnyGroup(user)) || tourStep !== 6}
                      onClick={() => {
                        updateAppCache('currentTour', 'checkListTour')
                        if (location.pathname !== '/home') {
                          navigate('/home')
                        }
                        setTimeout(() => {
                          setExplainSanboxDefaultState(true)
                        }, 500)
                        setTimeout(() => {
                          setExplainSanboxDefaultState(false)
                        }, 1000)
                        isChecklistTourOpen(false)
                        close()
                      }}>
                      {tourStep > 6 ? <CheckGnowbeIcon className={`absolute ${tourStep === 7 && 'animate-rotateOnce'}`} /> : <EmptyCircleIcon className={`absolute ${tourStep === 6 && 'bottom-1/2'} w-5 h-5`} />}
                      <div className={checklistDiv}>
                        <p className={`${tourStep > 6 ? 'line-through text-lightgray' : tourStep === 6 ? 'text-deepgray' : 'text-lightgray'} font-bold p-0 ${(!isAdmin(user) && !isAgentInAnyGroup(user)) && 'cursor-not-allowed'}`}
                        >All your programs and much more</p>
                        {tourStep === 6 && <p className="max-w-[220px]">Your program is shared with a test group and your group</p>}
                        {(!isAdmin(user) && !isAgentInAnyGroup(user)) && <p className="text-coral text-xs">You cannot perform this action. <span onClick={showLearnWhyModal} className="font-bold text-xm underline cursor-pointer">Learn why.</span></p>}
                      </div>
                    </button>
                  </div>
                  
                  <div className="border-b -mx-6 mt-4"></div>
                  <div className="flex justify-center items-center">
                    <Button
                      text="Dismiss checklist"
                      onClick={() => setDismiss(true)}
                      type="text"
                    />
                  </div>
                </>
                }
                {(tourStep === 7 || ((!isAdmin(user) && !isAgentInAnyGroup(user)) && tourStep === 4) || (!user.roles.includes('publisher') && !user.roles.includes('independentEditor') && tourStep === 3)) && <div className="flex flex-col gap-3 items-center justify-center px-6 py-14">
                  <img src="/images/party-popper-emoji.png" alt="" />
                  <div className="text-3xl">You’re good to go!</div>
                  <div>
                    <p className="mb-6">Congrats on completing the Creator Guide!
                      <br />
                      <br />
                      If you ever need to go through any part of this checklist again, <strong>you can dismiss this checklist</strong>, then click on the <strong>“Take the tour!”</strong> button at the top of the screen.</p>
                  </div>
                  <Button
                    type="chat"
                    onClick={() => {
                      setTourStep(0)
                      updateMeta('/newChecklistTour', '0', true)
                      updateMetaDeep('/tour/gnowbeChecklistTour', 'true', true, 'tour')
                      setTimeout(
                        () => {
                          setDismiss(false)
                          setCurrentStep(0)
                          showChecklist(false)
                        }, 0,
                      )
                      track({
                        event: 'Checklist Completed',
                        variables: {
                          user_id: user.id,
                        },
                      })
                    }
                    }
                    svgIcon={<CheckIcon className="w-4 h-4 mr-2" />}
                    text="Yes, dismiss checklist"
                  />
                </div>
                }
                {dismiss && <div className="flex flex-col gap-3 items-center justify-center px-6 pt-14">
                  <svg width="48" height="58" viewBox="0 0 48 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5714 57.1429C16.784 57.1429 13.7143 54.0731 13.7143 50.2857V41.1429C13.7143 37.3554 16.784 34.2857 20.5714 34.2857C21.9497 34.272 34.2857 32 34.2857 22.8571C34.2857 18.2857 29.7143 13.7143 22.8571 13.7143C17.2777 13.7143 13.7143 18.2857 13.7143 20.5714C13.7143 24.3589 10.6446 27.4286 6.85714 27.4286C3.06971 27.4286 0 24.3589 0 20.5714C0 9.42171 10.4686 0 22.8571 0C41.1429 0 48 13.6731 48 25.1429C48 34.6171 42.7954 41.8583 33.3417 45.5314C31.2846 46.3337 29.2503 46.8731 27.4286 47.2389V50.2857C27.4286 54.0731 24.3589 57.1429 20.5714 57.1429Z" fill="#CCD6DD" />
                  </svg>
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                    <path d="M7.57143 14.4286C11.3585 14.4286 14.4286 11.3586 14.4286 7.5715C14.4286 3.7844 11.3585 0.714355 7.57143 0.714355C3.78433 0.714355 0.714287 3.7844 0.714287 7.5715C0.714287 11.3586 3.78433 14.4286 7.57143 14.4286Z" fill="#CCD6DD" />
                  </svg>
                  <div className="text-3xl">Are you sure?</div>
                  <div>
                    <p className="mb-6">You will lose your current progress.</p>
                    <p>If you need to go through the checklist again, you can restart by clicking on the “Take the tour!” button at the top of the screen.</p>
                  </div>
                  <Button
                    type="chat"
                    onClick={() => {
                      setDismiss(false)
                      setTourStep(0)
                      showChecklist(false)
                      updateMeta('/newChecklistTour', '0', true)
                      updateMetaDeep('/tour/gnowbeChecklistTour', 'true', true, 'tour')
                      track({
                        event: 'Checklist Dismissed',
                        variables: {
                          tour_step: tourStep,
                          user_id: user.id,
                        },
                      })
                    }
                    }
                    svgIcon={<CheckIcon className="w-4 h-4 mr-2" />}
                    text="Yes, dismiss checklist"
                  />
                  <Button
                    type="text"
                    onClick={() => {
                      setDismiss(false)
                    }
                    }
                    text="No, take me back"
                  />
                </div>
                }

              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </div>
      : <div></div>
  )
}

export default ChecklistTour
