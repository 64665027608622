import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'

type ToggleProps = {
  id: string;
  label?: string;
  disabled?: boolean;
  defaultChecked: boolean;
  tooltip?: string;
  style?: Object;
  invertLabel?: boolean;
  onDisabledClick?: () => void;
  videoQualityToggle?: boolean;
  disabledTogleTooltipContent?: string;

  onChange: (v: boolean) => void;
}

const Toggle = forwardRef(({ id, label, disabled, defaultChecked, tooltip, style, invertLabel, onDisabledClick, onChange, videoQualityToggle, disabledTogleTooltipContent }: ToggleProps, ref) => {
  const [status, setStatus] = useState(defaultChecked)

  useEffect(() => {
    setStatus(defaultChecked)
  }, [defaultChecked])

  const handleChange = () => {
    setStatus(!status)
    onChange(!status)
  }

  useImperativeHandle(ref, () => ({
  }))

  const labelCls = classNames({
    disabled,
    'inverted mr-1.5': invertLabel,
    'tgl-label cursor-pointer': true,
  })

  const clsw = classNames({
    disabled,
    selected: status,
    'tgl-wrapper relative justify-between': true,
  })

  const videoQualityCls = classNames({
    disabled,
    selected: status,
    'tgl-wrapper relative justify-end': true,
  })

  const bgCls = classNames({
    'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200': true,
    'bg-team': status,
    'bg-gray-200': !status,
  })

  const tglCls = classNames({
    'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow ring-0 transition-transform ease-in-out duration-200': true,
    'translate-x-5': status,
    'translate-x-0': !status,
  })

  const labelCmp = <label className={labelCls} htmlFor={id}>{label}</label>

  if (invertLabel) {
    return (
      <Tippy placement="right" content={tooltip} disabled={!tooltip || disabled}>
        <div className={!videoQualityToggle ? clsw : videoQualityCls} style={style}>
          {disabled && onDisabledClick && (
            disabledTogleTooltipContent ? (
              <Tippy placement="top-end" content={disabledTogleTooltipContent}>
                <div className="absolute inset-0 z-10" onClick={onDisabledClick} />
              </Tippy>
            ) : (
              <div className="absolute inset-0 z-10" onClick={onDisabledClick} />
            )
          )}

          {label && <div className={labelCls}>{labelCmp}</div>}

          <button id={id} type="button" disabled={disabled} onClick={handleChange} aria-pressed="false" className="shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50">
            <span className="sr-only">{label}</span>
            <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md" />
            <span aria-hidden="true" className={bgCls} />
            <span aria-hidden="true" className={tglCls} />
          </button>
        </div>
      </Tippy>
    )
  }

  return (
    <div className={clsw} style={style}>
      <Tippy placement="right" content={tooltip} disabled={!tooltip || disabled}>
        <div className="inline-flex items-center">
          {disabled && onDisabledClick && (
            disabledTogleTooltipContent ? (
              <Tippy placement="left" content={disabledTogleTooltipContent}>
                <div className="absolute inset-0 z-10 cursor-pointer" onClick={onDisabledClick} />
              </Tippy>
            ) : (
              <div className="absolute inset-0 z-10 cursor-pointer" onClick={onDisabledClick} />
            )
          )}

          <button id={id} type="button" disabled={disabled} onClick={handleChange} aria-pressed="false" className="shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50">
            <span className="sr-only">{label}</span>
            <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md" />
            <span aria-hidden="true" className={bgCls} />
            <span aria-hidden="true" className={tglCls} />
          </button>

          {label && <div className="ml-3">{labelCmp}</div>}
        </div>
      </Tippy>
    </div>
  )
})

export default Toggle
