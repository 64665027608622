import React, { useState, useRef } from 'react'
import intl from 'react-intl-universal'
import { useMutation } from '@apollo/client'
import { ProgressReview, Reviewer } from 'graphql/schemas/review/Review'
import { SubmitCourseForReviewRes, SubmitCourseForReviewArgs, submitCourseForReviewMutation } from 'graphql/mutations/review/submitCourseForReview'
import { showModal } from 'components/utils/CustomModal'
import { showAlert } from 'components/utils/Alert'
import { isEmail } from 'utils/functions'
import { Button } from 'components/utils/Button'
import { useNavigate } from 'react-router-dom'

export const ManualReviewCourseCompleted = ({ companyId, courseId, userId, review, manualReviewsSkipEndorsment }: { companyId: string, courseId: string, userId: string, review: ProgressReview|null, manualReviewsSkipEndorsment: boolean }) => {
  const navigate = useNavigate()
  let alertVariant: any = 'info'
  if (!review || review.status === 'notSet') alertVariant = 'warning'
  if (review?.status === 'endorsmentRejected' || (review?.status === 'assessed' && !review.assessmentPassed)) alertVariant = 'danger'
  if (review?.status === 'assessed' && review.assessmentPassed) alertVariant = 'success'

  const doSomething = () => {
    if (review?.status === 'submitted') {
      return showModal({
        title: intl.get('manual_reviews_action_feedback_title'),
        content: intl.get('manual_reviews_submit_dialog_message'),
        secondaryButton: false,
      })
    }

    if (!review || review.status === 'notSet') {
      const modal = showModal({
        title: intl.get('manual_reviews_program_complete_title'),
        component: (
          <SubmitCourseModal
            companyId={companyId}
            courseId={courseId}
            userId={userId}
            reviewer={review?.reviewers.find(r => r.role === 'endorser') || null}
            status={review?.status || null}
            manualReviewsSkipEndorsment={manualReviewsSkipEndorsment}
            onComplete={() => modal.close()}
          />
        ),
        hideFooter: true,
      })
    }
    else {
      navigate('/learn/feedback')
    }
  }

  let titleText = intl.get('manual_reviews_program_complete_title')
  let subtitleText = intl.get('manual_reviews_program_complete_text')

  if (review?.status === 'submitted') {
    if (manualReviewsSkipEndorsment) {
      titleText = intl.get('manual_reviews_waiting_endorsement_title')
      subtitleText = intl.get('manual_reviews_in_progress_text')
    }
    else {
      titleText = intl.get('manual_reviews_waiting_endorsement_title')
      subtitleText = intl.get('manual_reviews_waiting_endorsement_text')
    }
  }
  else if (review?.status === 'endorsed') {
    titleText = intl.get('manual_reviews_waiting_review_title')
    subtitleText = intl.get('manual_reviews_waiting_review_text')
  }
  else if (review?.status === 'endorsmentRejected') {
    titleText = intl.get('manual_reviews_endorsement_failed_title')
    subtitleText = intl.get('manual_reviews_endorsement_failed_text')
  }
  else if (review?.status === 'assessed') {
    if (review.assessmentPassed) {
      titleText = intl.get('manual_reviews_assessments_passed_title')
      subtitleText = intl.get('manual_reviews_assessments_failed_text')
    }
    else {
      titleText = intl.get('manual_reviews_assessments_failed_title')
      subtitleText = intl.get('manual_reviews_assessments_failed_text')
    }
  }

  if (review?.status === 'notSet') {
    return (
      <div className="px-12 py-4 border border-actions-multiple_choice rounded-md text-actions-multiple_choice flex items-center space-x-4 mb-4">
        <div className="flex-1">
          <div className="text-xl font-bold mb-1">{intl.get('manual_reviews_program_complete_title')}</div>
          <div className="text-sm">{intl.get('manual_reviews_submit_feedback_locked_subtitle')}</div>
        </div>

        <div>
          <Button
            type="primary"
            text={intl.get('global_submit')}
            onClick={doSomething}
          />
        </div>
      </div>
    )
  }

  let divClassname = 'w-full px-12 py-4 rounded-md bg-actions-multiple_choice text-white text-center mb-4'
  if (review?.status === 'assessed' && review.assessmentPassed) divClassname = 'w-full px-12 py-4 rounded-md bg-actions-read text-white text-center mb-4'
  if (review?.status === 'endorsmentRejected' || review?.status === 'assessed' && !review.assessmentPassed) divClassname = 'w-full px-12 py-4 rounded-md bg-wrong text-white text-center mb-4'

  return (
    <button className={divClassname} onClick={doSomething}>
      <div className="text-xl font-bold mb-1">{titleText}</div>
      <div className="text-sm">{subtitleText}</div>
    </button>
  )
}

type SubmitCourseModalProps = {
  companyId: string;
  courseId: string;
  userId: string;
  reviewer: Reviewer|null;
  status: string|null;
  manualReviewsSkipEndorsment: boolean;
  onComplete: () => void;
}

const SubmitCourseModal = ({ companyId, courseId, userId, reviewer, status, manualReviewsSkipEndorsment, onComplete }: SubmitCourseModalProps) => {
  const [endorser, setEndorser] = useState({
    id: '',
    name: reviewer?.name || '',
    email: reviewer?.email || '',
    role: 'endorser' as 'endorser',
  })
  const [first, setFirst] = useState(true)
  const emailRef = useRef<HTMLInputElement>(null)

  const [submitCourse] = useMutation<SubmitCourseForReviewRes, SubmitCourseForReviewArgs>(submitCourseForReviewMutation, {
    onCompleted: () => {
      onComplete()
      showAlert(intl.get('manual_reviews_sumbit_sucess_text'), 'success')
    },
    onError: (error) => {
      showAlert(`Error - ${error}`, 'danger')
    },
  })

  const update = (field: string, value: string) => {
    const newRecord = {
      ...endorser,
      [field]: value,
    }
    setEndorser(newRecord)
    if (!value) {
      setFirst(false)
    }
  }

  const submit = () => {
    if (!manualReviewsSkipEndorsment && (!endorser.name || !endorser.email)) {
      return
    }
    if (endorser.email && !isEmail(endorser.email)) {
      if (emailRef?.current) emailRef.current.focus()
      return alert('Please insert a valid email address.')
    }
    const variables: SubmitCourseForReviewArgs = {
      companyId,
      courseId,
      userId,
      endorser: manualReviewsSkipEndorsment ? null : endorser,
    }
    submitCourse({ variables })
  }

  return (
    <div>
      {!manualReviewsSkipEndorsment && (
        <>
          <div className="font-bold uppercase text-sm text-actions-multiple_choice">{intl.get('manual_reviews_submit_feedback_choose_endorser')}</div>
          <p className="pb-4 mt-2">{intl.get('manual_reviews_submit_feedback_choose_endorser_description')}</p>

          <div className="font-bold mb-2">{intl.get('manual_reviews_submit_feedback_endorser_name')}</div>
          <p className="mb-0">
            <input type="text" placeholder={intl.get('manual_reviews_submit_feedback_endorser_name')} defaultValue={endorser.name || ''} onChange={e => update('name', e.target.value)} className={`form-input w-full ${!first && !endorser.name ? 'border-red-300 bg-red-100' : 'border-gray-100'}`} />
          </p>

          <div className="font-bold mb-2">{intl.get('manual_reviews_submit_feedback_endorser_email')}</div>
          <p className="mb-2">
            <input
              ref={emailRef}
              type="email"
              placeholder={intl.get('manual_reviews_submit_feedback_endorser_email')}
              defaultValue={endorser.email || ''}
              className={`w-full form-input ${!first && !endorser.email ? 'border-red-300 bg-red-100' : 'border-gray-100'}`}
              onChange={e => update('email', e.target.value)}
            />
          </p>
        </>
      )}

      <div className="font-bold text-red-700 uppercase text-sm">{intl.get('manual_reviews_submit_feedback_locked_title')}</div>
      <p className="mt-2">{intl.get('manual_reviews_submit_feedback_locked_subtitle')}</p>

      <Button
        text={intl.get('global_submit')}
        type="primary"
        fullWidth={true}
        onClick={submit}
        className="mb-4"
        disabled={(status || 'notSet') !== 'notSet' || (!manualReviewsSkipEndorsment && (!endorser.name || !endorser.email))}
      />
    </div>
  )
}
