import React from 'react'
import intl from 'react-intl-universal'
import { Menu, Transition } from '@headlessui/react'
import FeatureUpdatesIcon from 'components/FeatureUpdatesIcon'
import { HIDE_HELP } from 'constants/General'
import { trackButtonEnhanced } from 'utils/track'
import Tippy from '@tippyjs/react'


const HelpAndUpdateCenter = ({showTour, openTour, mobile, workspacesTour}: {showTour: boolean, openTour: () => void, mobile: boolean | undefined, workspacesTour: () => void}) => {

  return (
    <Menu>
      {({ open }) => (
        <div id={`${mobile ? 'mobile-question-mark' : 'question-mark'}`} className="relative">
          <Menu.Button >
            <Tippy content={intl.get('global_help')}>
              <span onClick={() => trackButtonEnhanced({
                button: 'Help And Updates',
                onScreen: 'Home (Horizontal Menu)',
              })} className="icon icon-question-circle-light text-xl font-medium cursor-pointer hover:text-coral transition-none"></span>
            </Tippy>
          </Menu.Button>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Menu.Items
              static
              className={'absolute w-56 right-0 mt-4 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none'}>
              <div className="py-1">
                {showTour && (
                <Menu.Item>
                  <button onClick={openTour} className="flex w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" className="fill-gray-700" viewBox="0 0 448 512">
                      <path d="M32 16C32 7.2 24.8 0 16 0S0 7.2 0 16V64 367v33 96c0 8.8 7.2 16 16 16s16-7.2 16-16V392l96.3-24.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L32 56V16zm0 73L140.7 61.8c30.7-7.7 63.1-4.1 91.4 10c55.3 27.7 120.4 27.7 175.8 0l8.1-4.1v278l-34.7 13c-37.9 14.2-80 12-116.2-6.1c-44.7-22.4-96-28-144.5-15.9L32 359V89z"/>
                    </svg>
                    <div className="ml-4">
                      Take the tour
                    </div>
                  </button>
                </Menu.Item>
                )}
                <Menu.Item>
                  <button onClick={workspacesTour} className="flex w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-gray-700" width="18" height="18" viewBox="0 0 576 512">
                      <path d="M208 144V80C208 53.49 229.5 32 256 32H320C346.5 32 368 53.49 368 80V144C368 170.5 346.5 192 320 192H304V240H464C490.5 240 512 261.5 512 288V320H528C554.5 320 576 341.5 576 368V432C576 458.5 554.5 480 528 480H464C437.5 480 416 458.5 416 432V368C416 341.5 437.5 320 464 320H480V288C480 279.2 472.8 272 464 272H304V320H320C346.5 320 368 341.5 368 368V432C368 458.5 346.5 480 320 480H256C229.5 480 208 458.5 208 432V368C208 341.5 229.5 320 256 320H272V272H112C103.2 272 96 279.2 96 288V320H112C138.5 320 160 341.5 160 368V432C160 458.5 138.5 480 112 480H48C21.49 480 0 458.5 0 432V368C0 341.5 21.49 320 48 320H64V288C64 261.5 85.49 240 112 240H272V192H256C229.5 192 208 170.5 208 144zM320 64H256C247.2 64 240 71.16 240 80V144C240 152.8 247.2 160 256 160H320C328.8 160 336 152.8 336 144V80C336 71.16 328.8 64 320 64zM32 368V432C32 440.8 39.16 448 48 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H48C39.16 352 32 359.2 32 368zM240 368V432C240 440.8 247.2 448 256 448H320C328.8 448 336 440.8 336 432V368C336 359.2 328.8 352 320 352H256C247.2 352 240 359.2 240 368zM448 368V432C448 440.8 455.2 448 464 448H528C536.8 448 544 440.8 544 432V368C544 359.2 536.8 352 528 352H464C455.2 352 448 359.2 448 368z"/>
                    </svg>
                    <div className="ml-4">
                      Workspaces explained
                    </div>
                  </button>
                </Menu.Item>

                {!HIDE_HELP.includes(process.env.BUILD || '') &&
                  <Menu.Item>
                    <a
                      href="https://gnowbe.zendesk.com/hc/en-us"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="flex w-full px-4 py-2 items-center text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                        <img src="/images/external-link-light.svg" className="h-[18px] w-[18px] inline-block mr-4" />
                        {/* <div className="mr-4">
                          <svg width="18" height="18" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 3.00008L1.9375 10.4167C1.77083 10.5834 1.57278 10.6667 1.34333 10.6667C1.11444 10.6667 0.916666 10.5834 0.75 10.4167C0.583333 10.2501 0.5 10.052 0.5 9.82258C0.5 9.59369 0.583333 9.39591 0.75 9.22925L8.16667 1.83341H1.83333C1.59722 1.83341 1.39917 1.75369 1.23917 1.59425C1.07972 1.43425 1 1.23619 1 1.00008C1 0.76397 1.07972 0.565915 1.23917 0.405915C1.39917 0.24647 1.59722 0.166748 1.83333 0.166748H10.1667C10.4028 0.166748 10.6006 0.24647 10.76 0.405915C10.92 0.565915 11 0.76397 11 1.00008V9.33341C11 9.56953 10.92 9.7673 10.76 9.92675C10.6006 10.0867 10.4028 10.1667 10.1667 10.1667C9.93056 10.1667 9.73278 10.0867 9.57333 9.92675C9.41333 9.7673 9.33333 9.56953 9.33333 9.33341V3.00008Z" fill="#4A4A4A"/>
                          </svg>
                        </div> */}
                      {intl.get('home_help_center')}
                    </a>
                  </Menu.Item>
                }
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  )
}

export default HelpAndUpdateCenter
