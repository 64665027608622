import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import DatePicker from 'react-date-picker'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { getTimezone, getDateFromDaysFromEpoch, range, millisToHms, getDaysFromEpoch2, nl2br } from 'utils/functions'
import Toggle from 'components/utils/Toggle'
import DailyMessaging from 'components/editor/DailyMessaging'
import { SimpleEditable } from 'components/utils/SimpleEditable'
import { showAlert } from 'components/utils/Alert'
import { Help } from 'components/utils/Help'
import { showModal } from 'components/utils/CustomModal'
import { Feature } from 'graphql/schemas/user/User'
import Lock from 'components/icons/Lock'
import Tippy from '@tippyjs/react'
import { UpsellModal } from 'components/modals/UpsellModal'
import { useMutation } from '@apollo/client'
import { SendUserLockedFeatureEmailVars, sendUserLockedFeatureEmailMutation } from 'graphql/mutations/emails/sendUserLockedFeatureEmail'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'

type AdditionalChapterProps = {
  chapter: Chapter;
  prevChapter: Chapter|undefined;
  nextChapter: Chapter|undefined;
  features: Feature[];
  update: (field: string, value: string|boolean|number|object) => void;
}

type Tab = 'general'|'chapter_daily_messaging'|'chapter_toggle_assessment_mode'

export const Additional = ({ chapter, prevChapter, nextChapter, features, update }: AdditionalChapterProps) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)

  const today = getDaysFromEpoch2(new Date())

  const [state, setState] = useState<{isDisabled: boolean, timeLimit: boolean, tab: Tab}>({
    isDisabled: chapter.disabledUntilDay && chapter.disabledUntilDay >= today || false,
    timeLimit: chapter.assessmentMode && !!chapter.assessmentMode.timeLimit || false,
    tab: 'general',
  })

  const calculateDaysFrom = (e) => {
    if (!e) return

    const rcvDate = new Date(e.toString())
    const date = new Date(rcvDate.getFullYear(), rcvDate.getMonth(), rcvDate.getDate())
    const diff2 = getDaysFromEpoch2(date)
    update('disabledUntilDay', diff2)
  }

  const assessmentsNum = chapter.actions.filter(a => a.contentType === 'assessment').length

  const currentChapterLocked = chapter && chapter.disabledUntilDay && chapter.disabledUntilDay > today
  const currentChapterUnlocked = !chapter.disabledUntilDay || (chapter.disabledUntilDay && chapter.disabledUntilDay <= today)
  const prevChapterLocked = prevChapter && prevChapter.disabledUntilDay && prevChapter.disabledUntilDay > today
  const nextChapterLocked = nextChapter && nextChapter.disabledUntilDay && nextChapter.disabledUntilDay > today
  const nextChapterUnlocked = !nextChapter || (nextChapter && !nextChapter.disabledUntilDay) || (nextChapter && nextChapter.disabledUntilDay && nextChapter.disabledUntilDay < today)

  const hours = range(0, 12)
  const minutes = range(0, 59)
  const timeLimitTime = chapter.assessmentMode && millisToHms(chapter.assessmentMode.timeLimit || 0) || { h: 0, m: 0, s: 0 }

  let hourSelect
  let minuteSelect

  const chapterHasNonAssessmentActions = chapter.actions.length > 0 && chapter.actions.some(a => a.contentType !== 'assessment')

  const tabStyle = (t: string) => {
    const inactive = 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-500 focus:outline-none focus:text-gray-700 focus:border-gray-500'
    const active = 'border-team font-medium text-gray-700 focus:outline-none focus:text-gray-800 focus:border-team'
    if (state.tab === t) return active
    return inactive
  }

  const changeTab = (t: Tab) => {
    setState({ ...state, tab: t })
  }

   // const chapterHasProctoring = !!chapter.assessmentMode?.proctoring
   // const canEnableProctoring = features.includes('editor_has_proctoring')
  const upsellAssessments = !features.includes('action-assessment')

  return (
    <div id="chapter-details-tabs">
      <div>
        <div className="sm:hidden mb-4">
          <select onChange={(e) => {
            if (e.target.value === 'chapter_toggle_assessment_mode' && upsellAssessments) return
            changeTab(e.target.value as Tab)
           }} aria-label="Selected tab" className="mt-1 form-select block w-full pl-3 pr-10 py-2">
            <option value="general">{intl.get('general')}</option>
            <option value="chapter_daily_messaging">{intl.get('chapter_daily_messaging')}</option>
            <option value="chapter_toggle_assessment_mode" disabled={upsellAssessments}>{intl.get('chapter_toggle_assessment_mode')}</option>
          </select>
        </div>

        <div className="hidden sm:block">
          <div className="border-b border-gray-400">
            <nav className="-mb-px flex space-x-6">
              <button onClick={() => changeTab('general')} className={`whitespace-nowrap text-center py-3 border-b-2 flex-1 ${tabStyle('general')}`}>
                {intl.get('general')}
              </button>

              <button id="chapter_daily_messaging" onClick={() => changeTab('chapter_daily_messaging')} className={`whitespace-nowrap text-center py-3 border-b-2 flex-1 ${tabStyle('chapter_daily_messaging')}`}>
                {intl.get('chapter_daily_messaging')}
              </button>

              <Tippy placement="bottom" content={intl.get('upsell_assessments_basic_settings_switch')} disabled={!upsellAssessments}>
                <button id="chapter_toggle_assessment_mode" onClick={() => {
                  if (upsellAssessments && state.tab !== 'chapter_toggle_assessment_mode') {
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type="upgrade_for_assessment_basic" track="assessment_mode_basic" close={() => {
                        modal.close()
                      }} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                    })
                    sendUserLockedFeatureEmail({
                      variables: {
                        feature: 'feature-locked-assessment-basic',
                        organizationId: activeWorkspaceId
                       },
                    })
                  }
                  changeTab('chapter_toggle_assessment_mode')
                }} className={`whitespace-nowrap text-center py-3 border-b-2 flex-1 ${tabStyle('chapter_toggle_assessment_mode')}`}>
                  {intl.get('chapter_toggle_assessment_mode')}

                  {upsellAssessments && <Lock className="ml-2 w-4 h-4 -mt-1 inline-block" />}
                </button>
              </Tippy>
            </nav>
          </div>
        </div>

        <div className="sm:px-4 sm:py-2">
          <section className={state.tab !== 'general' ? 'hidden' : ''}>
            <Toggle
              id="isSequential"
              label={intl.get('chapter_toggle_sequential_actions')}
              tooltip={intl.get('chapter_tooltip_sequential_actions')}
              defaultChecked={chapter.isSequential}
              onChange={v => update('isSequential', v)}
            />

            <Toggle
              id="isDisabled"
              label={intl.get('chapter_toggle_lock_session')}
              tooltip={intl.get('chapter_tooltip_lock_session')}
              disabled={
                (!!currentChapterLocked && !!prevChapterLocked) // today is locked and yesterday is locked
                || (!!currentChapterUnlocked && nextChapter && !!nextChapterUnlocked) // today is unlocked and tomorrow is unlocked
                || (!!prevChapterLocked && !!nextChapterLocked)
                || (chapter.order === 1 && !currentChapterLocked)
              }
              defaultChecked={state.isDisabled}
              onChange={(e) => {
                update('disabledUntilDay', !state.isDisabled ? (nextChapter?.disabledUntilDay ? nextChapter.disabledUntilDay : today + 90) : prevChapter?.disabledUntilDay || 0)
                setState({ ...state, isDisabled: e })
              }}
            />

            {state.isDisabled && (
              <div className="mb-3">
                <span className="inline-block mr-3 font-bold">{intl.get('chapter_edit_unlock_date_label')}</span>

                <DatePicker
                  value={getDateFromDaysFromEpoch(chapter.disabledUntilDay && chapter.disabledUntilDay || (nextChapter?.disabledUntilDay ? nextChapter.disabledUntilDay : today + 90), getTimezone())}
                  onChange={calculateDaysFrom}
                  maxDate={getDateFromDaysFromEpoch(nextChapter?.disabledUntilDay || 99999, getTimezone())}
                  minDate={getDateFromDaysFromEpoch(Math.max(prevChapter?.disabledUntilDay || 1, today), getTimezone())}
                />

                {chapter.disabledUntilDay === today && (
                  <Help
                    title={intl.get('global_tip')}
                    icon="icon-exclamation-circle-light"
                    classNames="bg-blue-200"
                    path="chapter_locked_timezone"
                  />
                )}

                <Help
                  path="firehelp_chapter_disabled"
                  hide={!chapter.disabledUntilDay || chapter.disabledUntilDay < today}
                />
              </div>
            )}
          </section>

          <section className={state.tab !== 'chapter_daily_messaging' ? 'hidden' : ''}>
            {chapter.order <= 1
              ? <div className="py-3 px-2">{intl.get('daily_messaging_disabled')}</div>
              : <DailyMessaging
                  key={`${chapter.id}-daily-messaging`}
                  id={chapter.id}
                  morningEmail={chapter.morningEmail}
                  morningPush={chapter.morningPush}
                  update={update}
                />
            }
          </section>

          <section className={state.tab !== 'chapter_toggle_assessment_mode' ? 'hidden' : ''}>
            <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium">
              {!chapter.assessmentMode?.enabled ? intl.getHTML('assessment_description') : intl.getHTML('assessment_description_enabled')}
            </div>
            <div className="grid grid-cols-7 gap-4 pb-2">
              <div className="pt-2.5 col-span-2">
                {intl.get('chapter_toggle_assessment_mode')}
              </div>

              <div className="col-span-5">
                <Toggle
                  id="assessmentMode"
                  label={!!chapter.assessmentMode?.enabled || false ? intl.get('global_enabled') : intl.get('global_disabled') }
                  tooltip={chapterHasNonAssessmentActions
                    ? intl.get('assessment_mode_disabled')
                    : intl.get('chapter_tooltip_assessment_mode')
                  }
                  defaultChecked={!!chapter.assessmentMode?.enabled || false}
                  disabled={chapterHasNonAssessmentActions || upsellAssessments}
                  disabledTogleTooltipContent={intl.get('assessment_mode_disabled')}
                  onDisabledClick={() => {
                    if (upsellAssessments) {
                      const modal = showModal({
                        title: intl.get('home_group_invite'),
                        component: <UpsellModal type="upgrade_for_assessment_basic" track="assessment_mode_basic" close={() => {
                          modal.close()
                        }} />,
                        onlyContent: true,
                        className: 'add-action-modal',
                      })
                      sendUserLockedFeatureEmail({
                        variables: {
                          feature: 'feature-locked-assessment-basic',
                          organizationId: activeWorkspaceId
                         },
                      })
                    }
                  }}
                  onChange={(e) => {
                    update('assessmentMode', produce({ enabled: false, randomized: false, limit: assessmentsNum, choicesRandomized: false, timeLimit: null }, (draft) => {
                      draft.enabled = e
                    }))
                  }}
                />

                <div className="text-sm text-medgray -mt-1">
                  {upsellAssessments
                    ? <span className="italic">{intl.get('upsell_assessments_basic_settings_switch')}</span>
                    : <div dangerouslySetInnerHTML={{ __html: nl2br(intl.get('assessments_basic_settings_help')) }} />
                  }
                </div>
              </div>
            </div>

            {!!chapter.assessmentMode?.enabled && (
              <>
                <div className="grid grid-cols-7 gap-4 my-2 py-2 border-t border-b border-gray-200">
                  <div className="pt-2.5 col-span-2">
                    {intl.get('settings_text')}
                  </div>

                  <div className="col-span-5">
                    <Toggle
                      id="randomizedAssessmentMode"
                      label={intl.get('chapter_assessment_mode_random')}
                      tooltip={intl.get('chapter_tooltip_assessment_mode_shuffle')}
                      defaultChecked={chapter.assessmentMode.randomized}
                      onChange={(e) => {
                        update('assessmentMode', produce(chapter.assessmentMode || {randomized: false}, (draft) => {
                          draft.randomized = e
                        }))
                      }}
                    />

                    <Toggle
                      id="choicesRandomized"
                      label={intl.get('chapter_assessment_mode_random_choices')}
                      tooltip={intl.get('chapter_tooltip_assessment_mode_random_choices')}
                      defaultChecked={!!chapter.assessmentMode.choicesRandomized}
                      onChange={(e) => {
                        update('assessmentMode', produce(chapter.assessmentMode || {choicesRandomized: false}, (draft) => {
                          draft.choicesRandomized = e
                        }))
                      }}
                    />

                    <div className="flex items-center mb-2">
                      <div className="mr-4 text-sm col-span-2">
                        {intl.get('chapter_assesment_mode_limit', { max: assessmentsNum })}:
                      </div>

                      <SimpleEditable
                        key={`${chapter.id}-asessment-limit`}
                        defaultValue={String(chapter.assessmentMode.limit || 0)}
                        placeholder={intl.get('chapter_assesment_mode_limit', { max: assessmentsNum })}
                        isEditing={true}
                        classname="w-20"
                        type="number"
                        update={(v) => {
                          if (isNaN(v)) {
                            return showAlert(intl.get('error_field_number'), 'danger')
                          }

                          if (v > assessmentsNum) {
                            return showAlert(`You only have ${assessmentsNum} assessment Actions.`, 'danger')
                          }

                          update('assessmentMode', produce(chapter.assessmentMode || {limit: 0}, (draft) => {
                            draft.limit = Number(v)
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div id="time-limit" className="grid grid-cols-7 gap-4">
                  <div className="pt-2.5 col-span-2">
                    {intl.get('time_limit')}
                  </div>

                  <div className="col-span-5">
                    <Toggle
                      id="enableTimeLimit"
                      label={intl.get('time_limit_toggle')}
                      defaultChecked={state.timeLimit}
                      onChange={(e) => {
                        setState({ ...state, timeLimit: !state.timeLimit })
                        update('assessmentMode', produce(chapter.assessmentMode || {timeLimit: 0}, (draft) => {
                          // draft.proctoring = (e && chapterHasProctoring) || false
                          draft.timeLimit = Number(state.timeLimit ? 0 : 3600000)
                        }))
                      }}
                    />

                    {state.timeLimit && (
                      <>
                        <div>
                          <select
                            ref={ref => hourSelect = ref}
                            defaultValue={timeLimitTime.h.toString()}
                            onChange={() => {
                              const limit = (hourSelect.value * 3600 + minuteSelect.value * 60) * 1000
                              update('assessmentMode', produce(chapter.assessmentMode || {timeLimit: 0}, (draft) => {
                                // draft.proctoring = chapterHasProctoring || false
                                draft.timeLimit = Number(limit)
                              }))
                            }}
                            className="editable inline text-sm">
                            {hours.map(n => <option key={n} value={n}>{`${n} ${intl.get('hour', { hour: n })}`}</option>)}
                          </select>


                          <select
                            ref={ref => minuteSelect = ref}
                            defaultValue={timeLimitTime.m.toString()}
                            onChange={() => {
                              const limit = (hourSelect.value * 3600 + minuteSelect.value * 60) * 1000
                              update('assessmentMode', produce(chapter.assessmentMode || {timeLimit: 0}, (draft) => {
                                // draft.proctoring = chapterHasProctoring || false
                                draft.timeLimit = Number(limit)
                              }))
                            }}
                            className="editable my-0 mx-3 inline text-sm">
                            {minutes.map(n => <option key={n} value={n}>{`${n} ${intl.get('minutes', { minutes: n })}`}</option>)}
                          </select>
                        </div>

                        {/* <Toggle
                          id="proctoring"
                          label="Proctoring"
                          tooltip={!canEnableProctoring ? 'This is a premium feature. Contact Gnowbe via the Help button on the bottom right of your screen to find out more about this feature.' : 'Give learners 2 additional minutes to complete system checks.'}
                          defaultChecked={chapterHasProctoring}
                          disabled={!(canEnableProctoring || chapterHasProctoring)}
                          onDisabledClick={() => {
                            if (!canEnableProctoring) {
                              trackButton({
                                button: 'toggle_proctoring',
                                on_screen: 'edit_session',
                              })
                              showModal({
                                title: 'Premium feature',
                                content: 'This is a premium feature. Contact Gnowbe via the Help button on the bottom right of your screen to find out more about this feature.',
                                secondaryButton: false,
                              })
                            }
                          }}
                          onChange={e => update('assessmentMode', produce(chapter.assessmentMode || {proctoring: false}, (draft) => { draft.proctoring = e }))}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  )
}
