import React from 'react'
import intl from 'react-intl-universal'
import { useQuery } from '@apollo/client'
import { unlockNextDay } from 'actions/courses_actions'
import { showModal } from 'components/utils/CustomModal'
import { GetChaptersForCourseRes, GetChaptersForCourseVars, getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { GQLStudyForSubscriptionResponse, GQLStudyForSubscriptionVariables, getStudyForSubscriptionQuery } from 'graphql/queries/user/getStudyForSubscription'
import { GetUserAndActiveCourseResponse, getUserAndActiveCourseQuery } from 'graphql/queries/user/getUser'
import { AppState } from 'graphql/queries/app/getAppState'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { calculateEngagement, getDaysFromEpoch2 } from 'utils/functions'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { Action } from 'graphql/schemas/action/Action'
import { Button } from 'components/utils/Button'
import { useNavigate } from 'react-router-dom'

export const NextChapterButton = ({ appData, currentChapterId, isEditor }: { appData: AppState, currentChapterId: string, isEditor: boolean }) => {
  const navigate = useNavigate()

  const [companyId, courseId] = appData.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: userData } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  const { data: chapterData } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: isEditor },
  })

  const { data: studyData } = useQuery<GQLStudyForSubscriptionResponse, GQLStudyForSubscriptionVariables>(getStudyForSubscriptionQuery, {
    skip: !appData?.appState.loggedInAs.uid,
    variables: { companyId, courseId, userId: appData?.appState.loggedInAs.uid || '' },
    fetchPolicy: 'cache-only',
  })

  const scrollTop = () => {
    const el = document.getElementById('learn-main')
    el?.scrollTo && el.scrollTo(0, 0)
  }

  const calcChapterEngagement = () => {
    if (!chapterData) return 0

    const chapter: Chapter|undefined = chapterData.chapters.find(c => c.id === currentChapterId)
    const study: UserStudy[] = chapter && studyData?.study.filter(s => s.chapterId === chapter.id) || []
    const actions: Action[] = chapter && chapter.actions || []
    return calculateEngagement(actions, study)
  }


  // const onClick = (id) => {
  //   scrollTop()
  //   navigate(`/learn/${id}`)
  // }

  const openNextChapter = (nc: Chapter|undefined) => {
    if (!nc || !userData) return

    const today = getDaysFromEpoch2(new Date())
    const chapterEngagement = calcChapterEngagement()

    if (nc && !nc.disabledUntilDay || (nc.disabledUntilDay && nc.disabledUntilDay <= today)) {
      if (userData.course.chaptersUnlocked === 'unlocked') {
        scrollTop()
        navigate(`/learn/${nc.id}`)
      }
      else {
        if (userData.course.chaptersUnlocked === 'daily') {
          if (!userData.subscription.progress || (userData.subscription.progress.currentDay && userData.subscription.progress.currentDay < nc.order)) {
            return showModal({
              title: intl.get('session_not_available_title'),
              content: intl.get('session_not_available_text'),
              secondaryButton: false,
              primaryText: intl.get('ok'),
            })
          }

          scrollTop()
          navigate(`/learn/${nc.id}`)
        }
        else { // streak
          if (chapterEngagement === 100 && (!nc.disabledUntilDay || nc.disabledUntilDay && nc.disabledUntilDay <= today)) {
            if (!userData.subscription.progress || userData.subscription?.progress.currentDay !== 999) {
              unlockNextDay(`${userData.subscription.companyId}-${userData.subscription.courseId}`, (userData.subscription.progress?.currentDay || 1) + 1)
            }
            scrollTop()
            navigate(`/learn/${nc.id}`)
          }
          else {
            return showModal({
              title: intl.get('session_not_completed_title'),
              content: intl.get('session_not_completed_text'),
              primaryText: intl.get('session_fragment_go_learn'),
              secondaryButton: false,
            })
          }
        }
      }
    }
    else {
      showModal({
        title: intl.get('session_locked_title'),
        content: intl.get('session_locked_text'),
        primaryText: intl.get('ok'),
        secondaryButton: false,
      })
    }
  }

  if (!chapterData) return null

  const currentChapter = chapterData.chapters.find(c => c.id === currentChapterId)
  const nextChapter = chapterData.chapters.slice().sort((a, b) => a.order - b.order).find(c => c.order > (currentChapter?.order || 1))

  if (!nextChapter) return null

  const today = getDaysFromEpoch2(new Date())

  return (
    <div className="text-center mt-4 mb-8 md:mb-0">
      <Button
        text={intl.get('session_next')}
        type="primary"
        icon="ml-8"
        rightIcon="icon-angle-right-light mr-8"
        disabled={nextChapter.disabledUntilDay && nextChapter.disabledUntilDay > today || false}
        onClick={() => openNextChapter(nextChapter)}
      />
    </div>
  )
}
